import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase-config';

import MyRoutes from './routes/MyRoutes';
import { ToastContainer } from 'react-toastify';

import './App.css';
import 'react-toastify/dist/ReactToastify.min.css';
import MyRoutesTest from './routes/MyRoutesTest';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('User is signed in', user);

        localStorage.setItem('userId', user.uid);
        localStorage.setItem('userName', user.displayName);
        localStorage.setItem('userEmail', user.email);
        localStorage.setItem('userCreatedAt', user.metadata.creationTime);
        localStorage.setItem('userLastLogin', user.metadata.lastSignInTime);
      } else {
        console.log('User is signed out');
        localStorage.clear();
        navigate('/');
      }
    });
  }, [auth]);

  return (
    <div className='App'>
      <MyRoutesTest />
      <ToastContainer
        position='bottom-right'
        autoClose={2000}
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        // pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
export default App;
