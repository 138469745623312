import React, { useState, useEffect } from 'react';
import {
  Dialog,
  Heading,
  TextInputField,
  FormField,
  Button,
  Pane,
  IconButton,
  SelectField,
  CrossIcon,
  Switch,
} from 'evergreen-ui';
import { Container, Row, Col, setConfiguration } from 'react-grid-system';

import { mutate } from 'swr';
import { useForm } from 'react-hook-form';

import { useQueryBanks } from '../../services/api/banksService';
import {
  getEntry,
  putEntry,
  useQueryEntries,
  useQueryEntry,
} from '../../services/api/entriesService';
import {
  getIntentColorByJournal,
  getNameByJournal,
} from '../../helpers/entriesHelper';
import styles from './EntryFormComponent.module.css';

import { toastNotification } from '../../shared/toastNotification';
import { inputTransferValidation } from '../../helpers/formHelper';
import { useSelector } from 'react-redux';

function TransferEditFormComponent({
  isShown,
  setIsShown,
  // journalEntry = {},
  setEntryJournalOnFocus,
}) {
  const companyId = localStorage.getItem('companyId');
  const [isDisabled, setIsDisabled] = useState(true);

  /* API calls */

  const { data: banks = [], isLoading: isLoadingBanks } = useQueryBanks(
    // company.data.id,
    companyId,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  // const { data: entry = [], isLoading: isLoadingEntry } =
  //   useQueryEntry(
  //     // company.data.id,
  //     journalEntry.id,
  //     {
  //       revalidateIfStale: false,
  //       revalidateOnFocus: false,
  //       revalidateOnReconnect: false,
  //     }
  //   );

  /* Form */

  const journalEntry = useSelector((state) => state.entryValues.transferEdit);

  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: journalEntry,
  });

  setConfiguration({
    containerWidths: '100%',
    gutterWidth: '12',
  });

  useEffect(() => {
    inputTransferValidation(
      watch('bankAccountId'),
      watch('bankAccountIdDestination'),
      watch('dateCompetencia'),
      watch('value'),
      setIsDisabled
    );
  }, [
    watch('bankAccountId'),
    watch('bankAccountIdDestination'),
    watch('dateCompetencia'),
    watch('value'),
  ]);

  const getTransferEntry = (journalEntry) => {
    const tempEntry = { ...journalEntry };
    tempEntry.dateCompetencia = tempEntry.dateCompetencia.substr(0, 10);
    tempEntry.dateVencimento = tempEntry.dateVencimento.substr(0, 10);
    console.log('getTransferEntry: ', tempEntry);
    if (!tempEntry.isInput) {
      tempEntry.bankAccountIdDestination =
        tempEntry.journalEntryFromRef.bankAccountId;
    } else {
      console.log(tempEntry);
      tempEntry.id = tempEntry.journalEntryRef.id;
      tempEntry.bankAccountIdDestination = tempEntry.bankAccountId;
      tempEntry.bankAccountId = tempEntry.journalEntryRef.bankAccountId;
    }
    reset(tempEntry);
    setValue(
      'value',
      tempEntry.value.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
      })
    );
    console.log('getTransferEntryUpdated: ', tempEntry);
  };

  useEffect(() => {
    isShown && getTransferEntry(journalEntry);
  }, [isShown, journalEntry]);

  const handleInput = (event) => {
    let inputVal = event.target.value.replace(/\D/g, '');
    if (inputVal === '') {
      setValue('value', '');
      return;
    }
    inputVal = inputVal.padStart(3, '0');
    const wholeNum = inputVal.slice(0, -2);
    const decimal = inputVal.slice(-2);
    const formattedWholeNum = Number(wholeNum).toLocaleString();
    const formattedValue = `${formattedWholeNum},${decimal}`;

    // Convert the formatted value to a number with two decimal places
    const numericValue = Number(
      formattedValue.replace(/\./g, '').replace(',', '.')
    );
    setValue('value', formattedValue);
  };

  const buildFormHeader = () => {
    return (
      <Pane
        display='flex'
        flex={1}
        justifyContent='space-between'
        alignItems='center'
      >
        <Button
          appearance='primary'
          intent={getIntentColorByJournal(journalEntry)}
          height={48}
          marginRight={16}
        >
          {getNameByJournal(journalEntry)}
        </Button>
        <Button
          appearance='minimal'
          height={28}
          marginRight={56}
          style={{
            background: '#BFFCC0',
            color: 'black',
          }}
          cursor='normal'
        >
          Modo Edição
        </Button>
        <IconButton appearance='minimal' icon={CrossIcon} onClick={onClose} />
      </Pane>
    );
  };

  const buildFormFooter = () => {
    return (
      <Pane>
        <Button
          form='transfer-form'
          type='submit'
          width='fit-content'
          disabled={isDisabled}
        >
          Salvar
        </Button>
      </Pane>
    );
  };

  const onSubmit = (entry) => {
    console.log('onSubmit');
    console.log('onSubmit entry: ', entry);
    let statusResponse = false;
    let toastContent = '';

    let entryToUpdate = {
      companyId: companyId,
      journalEntryType: 5,
      dateCompetencia: entry.dateCompetencia,
      dateVencimento: entry.dateCompetencia,
      description: entry.description,
      statusPayment:
        journalEntry.fitId !== null ||
        (journalEntry.journalEntryFromRef &&
          journalEntry?.journalEntryFromRef?.fitId !== null) ||
        (journalEntry.journalEntryRef &&
          journalEntry?.journalEntryRef?.fitId !== null)
          ? true
          : entry.statusPayment,
      paymentType: 1,
      value:
        typeof entry.value === typeof 5
          ? entry.value
          : Number(entry.value.replace(/\./g, '').replace(',', '.')),
      bankAccountId: entry.bankAccountId,
      bankAccountIdDestination: entry.bankAccountIdDestination,
      id: entry.id,
    };

    putEntry(entryToUpdate)
      .then((res) => {
        console.log('res put entry: ', res);
        mutate('entriesFilteredByDate');
        mutate('bankBalances');
        statusResponse = 'success';
        toastContent = 'Lançamento salvo com sucesso.';
        onClose();
      })
      .catch((err) => {
        statusResponse = 'warning';
        console.log('put err', err);
        toastContent = `Verifique os campos novamente.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
        mutate('banks');
      });
  };

  const onClose = () => {
    reset();
    setIsShown(false);
    // journalEntry = {};
    setEntryJournalOnFocus({});
  };

  const checkFitId = () => {
    let hasFitId;
    if (journalEntry.isInput) {
      hasFitId =
        journalEntry.journalEntryRef.fitId !== null ||
        journalEntry.fitId !== null;
      console.log(hasFitId);
    }

    return (
      journalEntry.journalEntryFromRef.fitId !== null ||
      journalEntry.fitId !== null
    );
  };

  return (
    <Dialog
      isShown={isShown}
      title=''
      onCloseComplete={onClose}
      confirmLabel='Custom Label'
      hasFooter={true}
      header={() => buildFormHeader()}
      footer={buildFormFooter}
      // shouldCloseOnOverlayClick={formState.isDirty ? onOverlayClick() : true}
      shouldCloseOnEscapePress={!formState.isDirty}
      width={800}
      backgroundColor='#f8f8f8'
    >
      <div className={styles.dialogWrapper}>
        <form id='transfer-form' onSubmit={handleSubmit(onSubmit)}>
          <FormField className={styles.formArea}>
            <Container
              fluid={true}
              style={{
                width: '100%',
                margin: 'none',
                padding: 'none',
              }}
            >
              <Row>
                <Col sm={12} md={12} lg={6}>
                  <SelectField
                    {...register('bankAccountId')}
                    label='Conta de Saída'
                    defaultValue={undefined}
                    onChange={(e) => setValue('bankAccountId', e.target.value)}
                    disabled={
                      journalEntry?.fitId !== null ||
                      (journalEntry.journalEntryFromRef &&
                        journalEntry?.journalEntryFromRef?.fitId !== null) ||
                      (journalEntry.journalEntryRef &&
                        journalEntry?.journalEntryRef?.fitId !== null)
                    }
                  >
                    <option value={undefined}></option>
                    {!isLoadingBanks &&
                      banks.data.map((bank) => {
                        return (
                          <option key={bank.id} value={bank.id}>
                            {bank.name}
                          </option>
                        );
                      })}
                  </SelectField>
                </Col>
                <Col sm={12} md={12} lg={6}>
                  <SelectField
                    {...register('bankAccountIdDestination')}
                    label='Conta de Entrada'
                    defaultValue={undefined}
                    disabled={
                      journalEntry?.fitId !== null ||
                      (journalEntry.journalEntryFromRef &&
                        journalEntry?.journalEntryFromRef?.fitId !== null) ||
                      (journalEntry.journalEntryRef &&
                        journalEntry?.journalEntryRef?.fitId !== null)
                    }
                    onChange={(e) =>
                      setValue('bankAccountIdDestination', e.target.value)
                    }
                  >
                    <option value={undefined}></option>

                    {!isLoadingBanks &&
                      banks.data.map((bank) => {
                        return (
                          <option key={bank.id} value={bank.id}>
                            {bank.name}
                          </option>
                        );
                      })}
                  </SelectField>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} lg={6}>
                  <TextInputField
                    {...register('dateCompetencia')}
                    label='Data da Transferência'
                    id='dateCompetencia'
                    name='dateCompetencia'
                    type='date'
                    onChange={(e) => {
                      setValue('dateCompetencia', e.target.value);
                    }}
                    disabled={
                      journalEntry?.fitId !== null ||
                      (journalEntry.journalEntryFromRef &&
                        journalEntry?.journalEntryFromRef?.fitId !== null) ||
                      (journalEntry.journalEntryRef &&
                        journalEntry?.journalEntryRef?.fitId !== null)
                    }
                  />
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <TextInputField
                    {...register('description')}
                    label='Descrição'
                    id='description'
                    name='description'
                    placeholder=''
                    defaultValue=''
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={10} sm={11} md={11} lg={12}>
                  <TextInputField
                    {...register('value', {
                      required: true,
                      // min: 0,
                      // valueAsNumber: true,
                    })}
                    defaultValue='0,00'
                    label='Valor (R$)'
                    type='text'
                    // min='0,00'
                    id='value'
                    name='value'
                    placeholder=''
                    // step='0.01'
                    onChange={handleInput}
                    disabled={
                      journalEntry?.fitId !== null ||
                      (journalEntry.journalEntryFromRef &&
                        journalEntry?.journalEntryFromRef?.fitId !== null) ||
                      (journalEntry.journalEntryRef &&
                        journalEntry?.journalEntryRef?.fitId !== null)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={2} sm={1} md={1} lg={1}>
                  <Heading size={400} marginBottom={16}>
                    Pago
                  </Heading>
                  <Switch
                    {...register('statusPayment')}
                    checked={watch('statusPayment')}
                    onChange={(e) => {
                      setValue('statusPayment', e.target.checked);
                    }}
                    disabled={
                      journalEntry?.fitId !== null ||
                      (journalEntry.journalEntryFromRef &&
                        journalEntry?.journalEntryFromRef?.fitId !== null) ||
                      (journalEntry.journalEntryRef &&
                        journalEntry?.journalEntryRef?.fitId !== null)
                    }
                  />
                </Col>
              </Row>
            </Container>
          </FormField>
        </form>
      </div>
    </Dialog>
  );
}

export default TransferEditFormComponent;
