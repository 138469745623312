import React, { useState } from 'react';

import { Checkbox, Heading, Pane, Table, Text, TextInput } from 'evergreen-ui';

import {
  parseStatementDate,
  parseStatementDateToForm,
} from '../../helpers/ofxHelper';
import { useDispatch, useSelector } from 'react-redux';
import { reconciliationValuesActions } from '../../store/reconciliationValues';

const StatementTable = ({
  statementsReconciliation,
  entriesReconciliation,
  setIsDrawerOpen,
}) => {
  /* API calls */

  const { myStatementList, showAllCheckbox } = useSelector(
    (state) => state.reconciliationValues
  );
  const dispatch = useDispatch();

  const handleStatement = (e) => {
    statementsReconciliation.data.map((statement) => {
      if (statement.id === e.id) {
        const tempStatement = {
          ...statement,
          statementObject: JSON.parse(statement.statementObject),
          isUploaded: false,
        };
        dispatch(
          reconciliationValuesActions.setCurrentStatement(tempStatement)
        );
      }
    });
    setIsDrawerOpen(false);
  };

  const [localSearch, setLocalSearch] = useState('');
  const [localDate, setLocalDate] = useState('');

  const handleDateFilter = (date, statement) => {
    const dateFilter = new Date(date);
    const startDate = new Date(
      parseStatementDateToForm(statement.statementObject.data.bankMsg.DTSTART)
    );
    const endDate = new Date(
      parseStatementDateToForm(statement.statementObject.data.bankMsg.DTEND)
    );

    return {
      dateFilter,
      startDate,
      endDate,
    };
  };

  const handleStatementsTable = () => {
    const filteredStatements = myStatementList
      .filter((statement) =>
        statement.bankName.toLowerCase().includes(localSearch)
      )
      .filter((statement) => {
        if (localDate) {
          const tempObj = handleDateFilter(localDate, statement);
          return (
            tempObj.dateFilter >= tempObj.startDate &&
            tempObj.dateFilter <= tempObj.endDate
          );
        } else {
          return statement;
        }
      })
      .filter((statement) => {
        if (showAllCheckbox) {
          return statement;
        } else {
          return statement.totalReconciliated !== statement.totalStatements;
        }
      });

    const finalArray = filteredStatements.map((statement) => {
      return (
        <Table.Row
          key={statement.id}
          isSelectable
          onSelect={() => handleStatement(statement)}
          height={40}
        >
          <Table.TextCell>{`${parseStatementDate(
            statement.statementObject.data.bankMsg.DTSTART
          )} a ${parseStatementDate(
            statement.statementObject.data.bankMsg.DTEND
          )}`}</Table.TextCell>
          <Table.TextCell>{statement.bankName}</Table.TextCell>
          <Table.TextCell>
            {`${statement.totalReconciliated}/${statement.totalStatements}`}
          </Table.TextCell>
        </Table.Row>
      );
    });

    if (finalArray.length === 0) {
      return (
        <Table.Row height={40}>
          <Table.TextCell>
            <Text>Nenhum extrato encontrado</Text>
          </Table.TextCell>
        </Table.Row>
      );
    }

    return finalArray;
  };

  return (
    <>
      <Pane
        display='flex'
        flexDirection='column'
        marginX={10}
        marginY={30}
        // width='100%'
        height='100%'
        // padding='8px'
      >
        <Heading marginY={10} size={600} textAlign='center'>
          Meus Extratos
        </Heading>
        <Pane display='flex' flexDirection='column' gap={6}>
          <Pane display='flex' gap={20} justifyContent='center'>
            <Pane display='flex' flexDirection='column'>
              <Heading size={300} textAlign='center' marginBottom={5}>
                Nome
              </Heading>
              <TextInput
                placeholder='Filtrar'
                width='200px'
                onChange={(e) => setLocalSearch(e.target.value.toLowerCase())}
              />
            </Pane>
            <Pane display='flex' flexDirection='column'>
              <Heading size={300} textAlign='center' marginBottom={5}>
                Período
              </Heading>
              <TextInput
                type='date'
                width='130px'
                onChange={(e) => {
                  setLocalDate(e.target.value);
                  console.log(e.target.value);
                  console.log(typeof e.target.value);
                }}
              />
            </Pane>
          </Pane>
          <Pane width='fit-content'>
            <Checkbox
              label='Mostrar todos'
              checked={showAllCheckbox}
              onChange={(e) =>
                dispatch(
                  reconciliationValuesActions.setShowAllCheckbox(
                    e.target.checked
                  )
                )
              }
            />
          </Pane>
        </Pane>
        <Pane textAlign='center'>
          <Table>
            <Table.Head height={25}>
              <Table.TextHeaderCell>Período</Table.TextHeaderCell>
              <Table.TextHeaderCell>Conta</Table.TextHeaderCell>
              <Table.TextHeaderCell>Conciliados/Total</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              <Pane overflowY='auto' maxHeight={320}>
                {handleStatementsTable()}
              </Pane>
            </Table.Body>
          </Table>
        </Pane>
      </Pane>
    </>
  );
};

export default StatementTable;
