import React from 'react';

import { TextInputField, FormField, Button, SelectField } from 'evergreen-ui';
import { Container, Row, Col } from 'react-grid-system';
import { useForm } from 'react-hook-form';

import {
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
} from 'firebase/auth';
import { mutate } from 'swr';
import { toastNotification } from '../../shared/toastNotification';
import { postEmployee } from '../../services/api/vitaUserService';
import { firebaseConfig } from '../../firebase-config';
import { initializeApp } from 'firebase/app';
import { useSelector } from 'react-redux';

const CreateEmployeeForm = () => {
  const companyId = localStorage.getItem('companyId');
  const userId = localStorage.getItem('userId');

  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({});

  const createNewEmployee = async (employeeForm) => {
    let statusResponse = '';
    let toastContent = '';
    let uid = '';

    const newEmployee = {
      name: employeeForm.createName,
      lastName: employeeForm.createLastname,
      email: employeeForm.createEmail,
      identityUserId: uid,
      // phoneNumber: employeeForm.createPhoneNumber,
      adminId: userId,
      companyId: companyId,
      userRole: employeeForm.createRole,
    };

    console.log('newEmployee', newEmployee);

    const tempApp = initializeApp(firebaseConfig, 'tempApp');
    const tempAuth = getAuth(tempApp);

    await createUserWithEmailAndPassword(
      tempAuth,
      employeeForm.createEmail,
      employeeForm.createPassword
    )
      .then((userCredential) => {
        const user = tempAuth.currentUser;
        console.log('userCredential: ', userCredential);
        console.log('user tempauth: ', user);
        newEmployee.identityUserId = userCredential.user.uid;

        postEmployee(newEmployee)
          .then((res) => {
            console.log('res post employee: ', res);
            statusResponse = 'success';
            toastContent = 'Usuário criado com sucesso.';
            mutate('userRolesWithUserId');
            reset();
          })
          .catch((err) => {
            statusResponse = 'warning';
            console.log('post err', err);
            toastContent = `Verifique os campos novamente.`;
            deleteUser(userCredential.user).then(() => {
              console.log('user deleted');
            });
          })
          .finally(() => {
            toastNotification(statusResponse, toastContent);
            tempAuth.signOut();
          });
      })
      .catch((error) => {
        console.log(error.code);
        if (error.code === 'auth/invalid-email') {
          statusResponse = 'warning';
          toastContent = 'Por favor, insira um e-mail válido.';
        } else if (error.code === 'auth/email-already-in-use') {
          statusResponse = 'warning';
          toastContent = 'E-mail já cadastrado.';
        } else if (error.code === 'auth/weak-password') {
          statusResponse = 'warning';
          toastContent = 'Sua senha deve conter pelo menos 6 caracteres.';
        } else {
          statusResponse = 'warning';
          toastContent =
            'Parece que falhamos aqui. Por favor, tente novamente.';
        }
        toastNotification(statusResponse, toastContent);
      });
  };

  return (
    <form id='create-form' onSubmit={handleSubmit(createNewEmployee)}>
      <FormField>
        <Container
          fluid={true}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginTop: '20px',
            width: '100%',
            maxWidth: '500px',
          }}
        >
          <Row>
            <Col>
              <TextInputField
                {...register('createName')}
                label='Nome'
                id='name'
                name='name'
                onChange={(e) => {
                  setValue('createName', e.target.value);
                }}
              />
            </Col>
            <Col>
              <TextInputField
                {...register('createLastname')}
                label='Sobrenome'
                id='sobrenome'
                name='sobrenome'
                onChange={(e) => {
                  setValue('createLastname', e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            {/* <Col>
              <TextInputField
                {...register('createPhoneNumber')}
                label='Telefone'
                id='phoneNumber'
                name='phoneNumber'
                type='tel'
                onChange={(e) => {
                  setValue('createPhoneNumber', e.target.value);
                }}
              />
            </Col> */}
            <Col>
              <SelectField
                {...register('createRole')}
                label='Nível de Acesso'
                defaultValue={undefined}
                onChange={(e) => setValue('createRole', e.target.value)}
              >
                <option value={undefined}></option>
                <option value='ADMIN'>ADMIN</option>
                <option value='ANALISTA'>ANALISTA</option>
                <option value='CONSULTOR'>CONSULTOR</option>
                <option value='LANÇADOR'>LANÇADOR</option>
              </SelectField>
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInputField
                {...register('createEmail')}
                label='E-mail'
                id='email'
                name='email'
                type='email'
                onChange={(e) => {
                  setValue('createEmail', e.target.value);
                }}
              />
            </Col>
            <Col>
              <TextInputField
                {...register('createPassword')}
                label='Senha'
                id='senha'
                name='senha'
                type='password'
                onChange={(e) => {
                  setValue('createPassword', e.target.value);
                }}
              />
            </Col>
          </Row>

          <Button
            type='submit'
            appearance='primary'
            intent='success'
            alignSelf='center'
          >
            Cadastrar
          </Button>
        </Container>
      </FormField>
    </form>
  );
};

export default CreateEmployeeForm;
