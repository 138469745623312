import React, { useState } from 'react';

import {
  Badge,
  BanCircleIcon,
  CaretDownIcon,
  CaretUpIcon,
  Dialog,
  DoubleCaretVerticalIcon,
  EditIcon,
  Heading,
  IconButton,
  Menu,
  MoreIcon,
  Pane,
  Popover,
  Position,
  Table,
  Text,
  TickCircleIcon,
  TrashIcon,
} from 'evergreen-ui';
import JournalEntry from './JournalEntry';
import { useDispatch, useSelector } from 'react-redux';
import { convertDate2 } from '../../helpers/dateConverter';
import EntryEditFormComponent from '../entries/EntryEditFormComponent';
import TransferEditFormComponent from '../entries/TransferEditFormComponent';
import { reconciliationValuesActions } from '../../store/reconciliationValues';
import { deleteEntry } from '../../services/api/entriesService';
import { mutate } from 'swr';
import { toastNotification } from '../../shared/toastNotification';

const ReconciliationJournalEntriesPane = ({
  handleDateSorting,
  dateSorting,
  handleValueSorting,
  valueSorting,
  isLoadingEntriesReconciliation,
  isValidatingEntriesReconciliation,
  entriesReconciliation,
  currentStatement,
}) => {
  const { entryList, statementList, selectedFilter } = useSelector(
    (state) => state.reconciliationValues
  );

  const [selected, setSelected] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [isShownDialog, setIsShownDialog] = useState(false);
  const [isShownTransferDialog, setIsShownTransferDialog] = useState(false);
  const [entryJournalOnFocus, setEntryJournalOnFocus] = useState(null);
  const dispatch = useDispatch();

  const openDialog = (journalEntry) => {
    setIsShownDialog(true);
    setEntryJournalOnFocus(journalEntry);
  };

  const openTransferDialog = (journalEntry) => {
    setIsShownTransferDialog(true);
    setEntryJournalOnFocus(journalEntry);
  };

  const handleSelected = (entry) => {
    const tempEntryList = [...entryList];
    if (!tempEntryList.some((item) => item.id === entry.id)) {
      dispatch(
        reconciliationValuesActions.setEntryList([...tempEntryList, entry])
      );
    } else {
      dispatch(
        reconciliationValuesActions.setEntryList(
          tempEntryList.filter((tempEntry) => tempEntry.id !== entry.id)
        )
      );
    }
    setSelected(!selected);
  };

  const deleteMutation = (entry) => {
    let statusResponse = false;
    let toastContent = '';
    deleteEntry(entry)
      .then((res) => {
        console.log('res delete entry: ', res);
        statusResponse = 'success';
        toastContent = 'Lançamento excluído com sucesso.';
        setIsShown(false);
        dispatch(reconciliationValuesActions.setEntryList([]));
        dispatch(reconciliationValuesActions.setStatementList([]));
      })
      .catch((err) => {
        statusResponse = 'warning';
        console.log('delete err', err);
        toastContent = `Erro ao excluir lançamento.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
        mutate('entriesFilteredByDate');
        mutate('entriesReconciliation');
        mutate('bankBalances');
        mutate('banks');
      });
    // deleteEntryOptions(entry)
  };

  const handleJournalEntries = () => {
    const filteredEntries = entriesReconciliation.data
      .filter(
        (entry) => entry.journalEntryType === 1 || entry.journalEntryType === 5
      )
      .filter((entry) => !entry.fitId)
      .filter((entry) => entry.bankAccountId === currentStatement.bankId)
      .filter((entry) => {
        if (selectedFilter === 'todos') {
          return entry;
        } else if (selectedFilter === 'entrada') {
          return entry.isInput === true;
        } else if (selectedFilter === 'saida') {
          return entry.isInput === false;
        }
      })
      .sort((a, b) => {
        if (dateSorting === 'asc') {
          return new Date(a.dateVencimento) - new Date(b.dateVencimento);
        } else if (dateSorting === 'desc') {
          return new Date(b.dateVencimento) - new Date(a.dateVencimento);
        } else {
          return 0;
        }
      })
      .sort((a, b) => {
        if (valueSorting === 'asc') {
          return a.valueFinal - b.valueFinal;
        } else if (valueSorting === 'desc') {
          return b.valueFinal - a.valueFinal;
        } else {
          return 0;
        }
      });

    console.log('filteredEntries', filteredEntries);

    const finalArray = filteredEntries.map((entry) => (
      <Table.Row
        key={entry.id}
        cursor='pointer'
        onClick={() => handleSelected(entry)}
        height={50}
        className={`statement-entry${
          entryList.some((item) => item.id === entry.id) ? '-selected' : ''
        }`}
      >
        <Table.TextCell>{convertDate2(entry.dateVencimento)}</Table.TextCell>
        <Table.TextCell>{entry.description || '-'}</Table.TextCell>
        <Table.TextCell>
          <Text
            color={
              entry.journalEntryType === 1
                ? entry.isInput
                  ? 'blue'
                  : 'red'
                : 'green'
            }
          >
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(entry.valueFinal)}
          </Text>
        </Table.TextCell>
        <Table.TextCell>
          {entry.statusPayment === true ? (
            <TickCircleIcon color='success' marginRight={16} />
          ) : (
            <BanCircleIcon color='warning' marginRight={16} />
          )}
        </Table.TextCell>
        <Table.TextCell>
          <Pane
            onClick={(e) => e.stopPropagation()}
            // width='fit-content'
            // display='flex'
          >
            <Popover
              position={Position.BOTTOM_LEFT}
              animationDuration={1}
              content={
                <Menu>
                  <Menu.Group>
                    <Menu.Item
                      icon={EditIcon}
                      intent='muted'
                      onClick={() => {
                        entry.journalEntryType === 5
                          ? openTransferDialog(entry)
                          : openDialog(entry);
                      }}
                    >
                      Editar
                    </Menu.Item>

                    <Menu.Item
                      icon={TrashIcon}
                      intent='danger'
                      onClick={() => {
                        setEntryJournalOnFocus(entry);
                        setIsShown(true);
                      }}
                    >
                      Deletar
                    </Menu.Item>
                  </Menu.Group>
                </Menu>
              }
            >
              <IconButton appearance='minimal' intent='info' icon={MoreIcon} />
            </Popover>
          </Pane>
        </Table.TextCell>
      </Table.Row>
    ));
    if (finalArray.length > 0) return finalArray;
    return (
      <Table.Row height={50}>
        <Table.TextCell>
          <Text size={400}>Nenhum lançamento encontrado</Text>
        </Table.TextCell>
      </Table.Row>
    );
  };

  return (
    <Pane className='vita-pane' borderRadius={10} width='30%'>
      <Heading marginY={10} textAlign='center'>
        VITA
      </Heading>
      <Pane textAlign='center'>
        <Table>
          <Table.Head height={40}>
            {/* <Table.TextHeaderCell>Tipo</Table.TextHeaderCell> */}

            <Table.TextHeaderCell>
              <Pane
                display='flex'
                alignItems='center'
                justifyContent='center'
                onClick={handleDateSorting}
                cursor='pointer'
              >
                Data
                {dateSorting === 'asc' ? (
                  <CaretUpIcon color='#3366FF' />
                ) : dateSorting === 'desc' ? (
                  <CaretDownIcon color='#3366FF' />
                ) : (
                  <DoubleCaretVerticalIcon color='#3366FF' />
                )}
              </Pane>
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>Descrição</Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <Pane
                display='flex'
                alignItems='center'
                justifyContent='center'
                onClick={handleValueSorting}
                cursor='pointer'
              >
                Valor
                {valueSorting === 'asc' ? (
                  <CaretUpIcon color='#3366FF' />
                ) : valueSorting === 'desc' ? (
                  <CaretDownIcon color='#3366FF' />
                ) : (
                  <DoubleCaretVerticalIcon color='#3366FF' />
                )}
              </Pane>
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>Status</Table.TextHeaderCell>
            <Table.TextHeaderCell>Ações</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            <Pane overflowY='scroll' maxHeight={250}>
              <EntryEditFormComponent
                isShown={isShownDialog}
                setIsShown={setIsShownDialog}
                journalEntry={entryJournalOnFocus}
              />
              <TransferEditFormComponent
                isShown={isShownTransferDialog}
                setIsShown={setIsShownTransferDialog}
                journalEntry={entryJournalOnFocus}
              />

              <Dialog
                isShown={isShown}
                title='Deletar lançamento'
                intent='danger'
                onCloseComplete={() => setIsShown(false)}
                confirmLabel='Deletar'
                cancelLabel='Cancelar'
                onConfirm={() => deleteMutation(entryJournalOnFocus.id)}
                preventBodyScrolling
              >
                Tem certeza que deseja deletar este lançamento?
              </Dialog>
              {!isLoadingEntriesReconciliation &&
                !isValidatingEntriesReconciliation &&
                handleJournalEntries()}
            </Pane>
          </Table.Body>
        </Table>
      </Pane>
    </Pane>
  );
};

export default ReconciliationJournalEntriesPane;
