// Helper function to format date as DD/MM/YYYY
export const formatDate = (dateString) => {
  const date = dateString.substr(0, 8); // Extract the date part (YYYYMMDD)
  const year = date.substr(0, 4);
  const month = date.substr(4, 2);
  const day = date.substr(6, 2);
  return `${day}/${month}/${year}`;
};

// Helper function to convert values to BRL using Intl.NumberFormat
export const formatToBRL = (value) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
};

export const parseStatementDate = (dateString) => {
  const year = parseInt(dateString.substr(0, 4), 10);
  const month = parseInt(dateString.substr(4, 2), 10) - 1; // Month is zero-indexed in Date objects
  const day = parseInt(dateString.substr(6, 2), 10);
  // console.log(new Date(year, month, day).toLocaleDateString());
  return new Date(year, month, day).toLocaleDateString();
};
export const parseStatementDateToFetch = (dateString) => {
  const year = parseInt(dateString.substr(0, 4), 10);
  const month = parseInt(dateString.substr(4, 2), 10) - 1; // Month is zero-indexed in Date objects
  const day = parseInt(dateString.substr(6, 2), 10);
  console.log(new Date(year, month, day).toISOString());
  return new Date(year, month, day).toISOString();
};
export const parseStatementDateToDisplay = (dateString) => {
  const year = parseInt(dateString.substr(0, 4), 10);
  const month = parseInt(dateString.substr(4, 2), 10) - 1; // Month is zero-indexed in Date objects
  const day = parseInt(dateString.substr(6, 2), 10);
  return new Date(year, month, day);
};
export const parseStatementDateToForm = (dateString) => {
  const year = dateString.substr(0, 4);
  const month = dateString.substr(4, 2);
  const day = dateString.substr(6, 2);
  console.log(`${year}-${month}-${day}`);
  return `${year}-${month}-${day}`;
};
export const banksList = [
  {
    Código: '00001',
    'Nome da instituição': 'BANCO DO BRASIL S.A.',
    CNPJ: '00000000',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '04070',
    'Nome da instituição': 'BRB - BANCO DE BRASILIA S.A.',
    CNPJ: '00000208',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '57446',
    'Nome da instituição': 'AGK CORRETORA DE CAMBIO S.A.',
    CNPJ: '00250699',
    Atividade: 'CORRETORAS DE CÂMBIO',
  },
  {
    Código: '21104',
    'Nome da instituição': 'CAIXA ECONOMICA FEDERAL',
    CNPJ: '00360305',
    Atividade: 'CAIXAS ECONOMICAS',
  },
  {
    Código: '32429',
    'Nome da instituição': 'BANCO INTER S.A.',
    CNPJ: '00416968',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '38070',
    'Nome da instituição':
      'COLUNA S/A DISTRIBUIDORA DE TITULOS E VALORES MOBILIÁRIOS',
    CNPJ: '00460065',
    Atividade: 'DISTRIBUIDORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '05739',
    'Nome da instituição': 'BANCO CETELEM S.A.',
    CNPJ: '00558456',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '05743',
    'Nome da instituição': 'BANCO SEMEAR S.A.',
    CNPJ: '00795423',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '57466',
    'Nome da instituição': 'PLANNER CORRETORA DE VALORES S.A.',
    CNPJ: '00806535',
    Atividade: 'CORRETORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '37972',
    'Nome da instituição': 'BANCO B3 S.A.',
    CNPJ: '00997185',
    Atividade: 'BANCOS COMERCIAIS',
  },
  {
    Código: '07747',
    'Nome da instituição': 'BANCO RABOBANK INTERNATIONAL BRASIL S.A.',
    CNPJ: '01023570',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '05748',
    'Nome da instituição': 'BANCO COOPERATIVO SICREDI S.A.',
    CNPJ: '01181521',
    Atividade: 'BANCOS COOPERATIVOS',
  },
  {
    Código: '07752',
    'Nome da instituição': 'BANCO BNP PARIBAS BRASIL S.A.',
    CNPJ: '01522368',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '05399',
    'Nome da instituição': 'KIRTON BANK S.A. - BANCO MÚLTIPLO',
    CNPJ: '01701201',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '05756',
    'Nome da instituição': 'BANCO COOPERATIVO SICOOB S.A. - BANCO SICOOB',
    CNPJ: '02038232',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '07757',
    'Nome da instituição': 'BANCO KEB HANA DO BRASIL S.A.',
    CNPJ: '02318507',
    Atividade: 'BANCOS COMERCIAIS',
  },
  {
    Código: '08844',
    'Nome da instituição':
      'XP INVESTIMENTOS CORRETORA DE CAMBIO,TÍTULOS E VALORES MOBILIÁRIOS S/A',
    CNPJ: '02332886',
    Atividade: 'CORRETORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '07860',
    'Nome da instituição': 'BANCO MORGAN STANLEY S.A.',
    CNPJ: '02801938',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '08493',
    'Nome da instituição': 'TREVISO CORRETORA DE CAMBIO S.A.',
    CNPJ: '02992317',
    Atividade: 'CORRETORAS DE CÂMBIO',
  },
  {
    Código: '32442',
    'Nome da instituição': 'HIPERCARD BANCO MÚLTIPLO S.A.',
    CNPJ: '03012230',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '08578',
    'Nome da instituição': 'BANCO J. SAFRA S.A.',
    CNPJ: '03017677',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '08932',
    'Nome da instituição': 'AVIPAM CORRETORA DE CAMBIO LTDA',
    CNPJ: '03443143',
    Atividade: 'CORRETORAS DE CÂMBIO',
  },
  {
    Código: '09313',
    'Nome da instituição': 'BANCO ABN AMRO S.A.',
    CNPJ: '03532415',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '11458',
    'Nome da instituição': 'BANCO CARGILL S.A.',
    CNPJ: '03609817',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '12407',
    'Nome da instituição': 'OZ CORRETORA DE CAMBIO S.A.',
    CNPJ: '04062902',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '14290',
    'Nome da instituição': 'GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A.',
    CNPJ: '04332281',
    Atividade: 'BANCOS MULTIPLOS SEM CARTEIRA COMERCIAL',
  },
  {
    Código: '13362',
    'Nome da instituição':
      'ARC CORRETORA DE CAMBIO, ASSOCIADOS GOUVEIA, CAMPEDELLI S.A.',
    CNPJ: '04684647',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '15115',
    'Nome da instituição': 'BANCO INBURSA S.A.',
    CNPJ: '04866275',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '01003',
    'Nome da instituição': 'BANCO DA AMAZONIA S.A.',
    CNPJ: '04902979',
    Atividade: 'BANCOS COMERCIAIS',
  },
  {
    Código: '14203',
    'Nome da instituição': 'CONFIDENCE CORRETORA DE CAMBIO S.A.',
    CNPJ: '04913129',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '04037',
    'Nome da instituição': 'BANCO DO ESTADO DO PARÁ S.A.',
    CNPJ: '04913711',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '17577',
    'Nome da instituição':
      'GENIAL INSTITUCIONAL CORRETORA DE CAMBIO, TÍTULOS E VALORES MOBILIÁRIO',
    CNPJ: '05816451',
    Atividade: 'CORRETORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '17796',
    'Nome da instituição': 'LABOR SOCIEDADE CORRETORA DE CAMBIO LTDA.',
    CNPJ: '06132348',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '18066',
    'Nome da instituição': 'BOA VIAGEM SOCIEDADE CORRETORA DE CAMBIO LTDA.',
    CNPJ: '06373777',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '02004',
    'Nome da instituição': 'BANCO DO NORDESTE DO BRASIL S.A.',
    CNPJ: '07237373',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '20198',
    'Nome da instituição': 'ONNIX CORRETORA DE CAMBIO LTDA.',
    CNPJ: '07333726',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '05320',
    'Nome da instituição':
      'CHINA CONSTRUCTION BANK (BRASIL) BANCO MÚLTIPLO S/A',
    CNPJ: '07450604',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '22136',
    'Nome da instituição': 'BANCO KDB DO BRASIL S.A.',
    CNPJ: '07656500',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '22417',
    'Nome da instituição': 'BANCO TOPÁZIO S.A.',
    CNPJ: '07679404',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '23655',
    'Nome da instituição': 'SOL CORRETORA DE CAMBIO LTDA.',
    CNPJ: '08520517',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '24214',
    'Nome da instituição': 'MONEYCORP BANCO DE CAMBIO S.A.',
    CNPJ: '08609934',
    Atividade: 'BANCOS DE CâMBIO',
  },
  {
    Código: '25755',
    'Nome da instituição': 'STATE STREET BRASIL S.A. - BANCO COMERCIAL',
    CNPJ: '09274232',
    Atividade: 'BANCOS COMERCIAIS',
  },
  {
    Código: '57695',
    'Nome da instituição': 'CODEPE CORRETORA DE VALORES E CAMBIO S.A.',
    CNPJ: '09512542',
    Atividade: 'CORRETORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '26043',
    'Nome da instituição': 'PICPAY BANK - BANCO MÚLTIPLO S.A',
    CNPJ: '09516419',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '27160',
    'Nome da instituição': 'BANCO DA CHINA BRASIL S.A.',
    CNPJ: '10690848',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '27143',
    'Nome da instituição': 'GET MONEY CORRETORA DE CAMBIO S.A.',
    CNPJ: '10853017',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '27930',
    'Nome da instituição':
      'OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
    CNPJ: '11495073',
    Atividade: 'DISTRIBUIDORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '27857',
    'Nome da instituição': 'TRAVELEX BANCO DE CAMBIO S.A.',
    CNPJ: '11703662',
    Atividade: 'BANCOS DE CâMBIO',
  },
  {
    Código: '28632',
    'Nome da instituição':
      'MIRAE ASSET WEALTH MANAGEMENT (BRAZIL) CORRETORA DE CAMBIO, TÍTULOS E',
    CNPJ: '12392983',
    Atividade: 'CORRETORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '28866',
    'Nome da instituição': 'BEXS BANCO DE CAMBIO S/A',
    CNPJ: '13059145',
    Atividade: 'BANCOS DE CâMBIO',
  },
  {
    Código: '30038',
    'Nome da instituição': 'BR PARTNERS BANCO DE INVESTIMENTO S.A.',
    CNPJ: '13220493',
    Atividade: 'BANCOS DE INVESTIMENTO',
  },
  {
    Código: '28978',
    'Nome da instituição':
      'FRAM CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    CNPJ: '13673855',
    Atividade: 'DISTRIBUIDORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '29033',
    'Nome da instituição': 'BANCO WESTERN UNION DO BRASIL S.A.',
    CNPJ: '13720915',
    Atividade: 'BANCOS COMERCIAIS',
  },
  {
    Código: '28996',
    'Nome da instituição': 'WESTERN UNION CORRETORA DE CAMBIO S.A.',
    CNPJ: '13728156',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '29399',
    'Nome da instituição': 'CAMBIONET CORRETORA DE CAMBIO LTDA.',
    CNPJ: '14190547',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '05254',
    'Nome da instituição': 'PARANÁ BANCO S.A.',
    CNPJ: '14388334',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '29781',
    'Nome da instituição': 'MEGA CORRETORA DE CAMBIO LTDA.',
    CNPJ: '15077393',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '31107',
    'Nome da instituição': 'BANCO BOCOM BBM S.A.',
    CNPJ: '15114366',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '29634',
    'Nome da instituição': 'LÚMINA CORRETORA DE CAMBIO LTDA.',
    CNPJ: '15122605',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '29428',
    'Nome da instituição': 'CONSEGTUR CORRETORA DE CAMBIO LTDA.',
    CNPJ: '15168152',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '29854',
    'Nome da instituição': 'BANCO WOORI BANK DO BRASIL S.A.',
    CNPJ: '15357060',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '29991',
    'Nome da instituição': 'TURCAMBIO - CORRETORA DE CAMBIO LTDA.',
    CNPJ: '15482499',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '30097',
    'Nome da instituição': 'CORRETORA DE CAMBIO AÇORIANA LIMITADA.',
    CNPJ: '15761217',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '30392',
    'Nome da instituição': 'SINGRATUR CORRETORA DE CAMBIO LTDA',
    CNPJ: '16854999',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '30087',
    'Nome da instituição': 'AMAZÔNIA CORRETORA DE CAMBIO LTDA.',
    CNPJ: '16927221',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '30138',
    'Nome da instituição': 'BROKER BRASIL CORRETORA DE CAMBIO LTDA.',
    CNPJ: '16944141',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '05389',
    'Nome da instituição': 'BANCO MERCANTIL DO BRASIL S.A.',
    CNPJ: '17184037',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '04048',
    'Nome da instituição': 'BANCO ITAÚ BBA S.A.',
    CNPJ: '17298092',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '57126',
    'Nome da instituição':
      'H H PICCHIONI S/A CORRETORA DE CAMBIO E VALORES MOBILIARIOS',
    CNPJ: '17312083',
    Atividade: 'CORRETORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '57008',
    'Nome da instituição':
      'AMARIL FRANKLIN CORRETORA DE TÍTULOS E VALORES LTDA',
    CNPJ: '17312661',
    Atividade: 'CORRETORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '37775',
    'Nome da instituição':
      'COTACAO DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS S.A',
    CNPJ: '17354911',
    Atividade: 'DISTRIBUIDORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '30260',
    'Nome da instituição': 'ICBC DO BRASIL BANCO MÚLTIPLO S.A.',
    CNPJ: '17453575',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '30700',
    'Nome da instituição': 'INVEST CORRETORA DE CAMBIO LTDA.',
    CNPJ: '17508380',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '30673',
    'Nome da instituição': 'CONEXION CORRETORA DE CAMBIO LTDA.',
    CNPJ: '17635177',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '30735',
    'Nome da instituição': "VIP'S CORRETORA DE CAMBIO LTDA.",
    CNPJ: '17772370',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '30423',
    'Nome da instituição': 'BRX CORRETORA DE CAMBIO LTDA.',
    CNPJ: '17904906',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '30916',
    'Nome da instituição': 'NUMATUR CORRETORA DE CAMBIO LTDA.',
    CNPJ: '18145784',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '30389',
    'Nome da instituição': 'CONECTA CORRETORA DE CAMBIO LTDA.',
    CNPJ: '18287740',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '30686',
    'Nome da instituição': 'UBS BRASIL BANCO DE INVESTIMENTO S.A.',
    CNPJ: '18520834',
    Atividade: 'BANCOS DE INVESTIMENTO',
  },
  {
    Código: '31314',
    'Nome da instituição': 'EXECUTIVE CORRETORA DE CAMBIO LTDA.',
    CNPJ: '19086249',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '30961',
    'Nome da instituição': 'BRAZA BANK S.A. BANCO DE CAMBIO',
    CNPJ: '19307785',
    Atividade: 'BANCOS DE CâMBIO',
  },
  {
    Código: '37637',
    'Nome da instituição':
      'PARMETAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
    CNPJ: '20155248',
    Atividade: 'DISTRIBUIDORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '31877',
    'Nome da instituição':
      'GLOBAL EXCHANGE DO BRASIL SOCIEDADE CORRETORA DE CAMBIO LTDA.',
    CNPJ: '21040668',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '32731',
    'Nome da instituição': 'GOOD CORRETORA DE CAMBIO LTDA',
    CNPJ: '23010182',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '57523',
    'Nome da instituição': 'GUITTA CORRETORA DE CAMBIO LTDA.',
    CNPJ: '24074692',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '40060',
    'Nome da instituição': 'AVS CORRETORA DE CAMBIO LTDA.',
    CNPJ: '25280945',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '40791',
    'Nome da instituição':
      'IB CORRETORA DE CAMBIO, TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    CNPJ: '27842177',
    Atividade: 'CORRETORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '57101',
    'Nome da instituição':
      'ELITE CORRETORA DE CAMBIO E VALORES MOBILIÁRIOS LTDA',
    CNPJ: '28048783',
    Atividade: 'CORRETORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '04021',
    'Nome da instituição': 'BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO',
    CNPJ: '28127603',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '05246',
    'Nome da instituição': 'BANCO ABC BRASIL S.A.',
    CNPJ: '28195667',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '40953',
    'Nome da instituição':
      'GALAPAGOS CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    CNPJ: '28650236',
    Atividade: 'DISTRIBUIDORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '41301',
    'Nome da instituição': 'SADOC SOCIEDADE CORRETORA DE CAMBIO LTDA.',
    CNPJ: '28762249',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '41122',
    'Nome da instituição': 'STONEX BANCO DE CAMBIO S.A.',
    CNPJ: '28811341',
    Atividade: 'BANCOS DE CâMBIO',
  },
  {
    Código: '07751',
    'Nome da instituição': 'SCOTIABANK BRASIL S.A. BANCO MÚLTIPLO',
    CNPJ: '29030467',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '41814',
    'Nome da instituição': 'TROCKA CORRETORA DE CAMBIO LTDA.',
    CNPJ: '30183111',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '05208',
    'Nome da instituição': 'BANCO BTG PACTUAL S.A.',
    CNPJ: '30306294',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '05746',
    'Nome da instituição': 'BANCO MODAL S.A.',
    CNPJ: '30723886',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '42122',
    'Nome da instituição': 'BANCO C6 S.A.',
    CNPJ: '31872495',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '05612',
    'Nome da instituição': 'BANCO GUANABARA S.A.',
    CNPJ: '31880826',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '07604',
    'Nome da instituição': 'BANCO INDUSTRIAL DO BRASIL S.A.',
    CNPJ: '31895683',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '57287',
    'Nome da instituição': 'FAIR CORRETORA DE CAMBIO S.A.',
    CNPJ: '32648370',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '06300',
    'Nome da instituição': 'BANCO DE LA NACION ARGENTINA',
    CNPJ: '33042151',
    Atividade: 'BANCOS COMERCIAIS',
  },
  {
    Código: '06477',
    'Nome da instituição': 'CITIBANK N.A.',
    CNPJ: '33042953',
    Atividade: 'BANCOS COMERCIAIS',
  },
  {
    Código: '07376',
    'Nome da instituição': 'BANCO J.P. MORGAN S.A.',
    CNPJ: '33172537',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '42424',
    'Nome da instituição': 'BANCO XP S.A.',
    CNPJ: '33264668',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '06473',
    'Nome da instituição': 'BANCO CAIXA GERAL - BRASIL S.A.',
    CNPJ: '33466988',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '07745',
    'Nome da instituição': 'BANCO CITIBANK S.A.',
    CNPJ: '33479023',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '11007',
    'Nome da instituição':
      'BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL',
    CNPJ: '33657248',
    Atividade: 'BANCOS DE DESENVOLVIMENTO',
  },
  {
    Código: '57015',
    'Nome da instituição':
      'ATIVA INVESTIMENTOS S.A. CORRETORA DE TÍTULOS, CAMBIO E VALORES',
    CNPJ: '33775974',
    Atividade: 'CORRETORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '37595',
    'Nome da instituição':
      'DILLON S/A DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS',
    CNPJ: '33851064',
    Atividade: 'DISTRIBUIDORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '04029',
    'Nome da instituição': 'BANCO ITAÚ CONSIGNADO S.A.',
    CNPJ: '33885724',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '57229',
    'Nome da instituição':
      'MASTER S/A CORRETORA DE CAMBIO, TÍTULOS E VALORES MOBILIÁRIOS',
    CNPJ: '33886862',
    Atividade: 'CORRETORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '07225',
    'Nome da instituição':
      'BRKB DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    CNPJ: '33923111',
    Atividade: 'DISTRIBUIDORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '05243',
    'Nome da instituição': 'BANCO MASTER S/A',
    CNPJ: '33923798',
    Atividade: 'BANCOS COMERCIAIS',
  },
  {
    Código: '31162',
    'Nome da instituição': 'BANCO DE INVESTIMENTOS CREDIT SUISSE (BRASIL) S.A.',
    CNPJ: '33987793',
    Atividade: 'BANCOS DE INVESTIMENTO',
  },
  {
    Código: '53006',
    'Nome da instituição': 'HAITONG BANCO DE INVESTIMENTO DO BRASIL S.A.',
    CNPJ: '34111187',
    Atividade: 'BANCOS DE INVESTIMENTO',
  },
  {
    Código: '57268',
    'Nome da instituição': 'INTERCAM CORRETORA DE CAMBIO LTDA.',
    CNPJ: '34265629',
    Atividade: 'CORRETORAS DE CÂMBIO',
  },
  {
    Código: '12955',
    'Nome da instituição': 'MONOPÓLIO CORRETORA DE CAMBIO LTDA.',
    CNPJ: '34666362',
    Atividade: 'CORRETORAS DE CÂMBIO',
  },
  {
    Código: '43344',
    'Nome da instituição': 'WISE BRASIL CORRETORA DE CAMBIO LTDA',
    CNPJ: '36588217',
    Atividade: 'CORRETORAS DE CÂMBIO',
  },
  {
    Código: '43120',
    'Nome da instituição': 'BANCO XCMG BRASIL S.A.',
    CNPJ: '36658769',
    Atividade: 'BANCOS MULTIPLOS SEM CARTEIRA COMERCIAL',
  },
  {
    Código: '13023',
    'Nome da instituição': 'BANCO DE DESENVOLVIMENTO DE MINAS GERAIS S.A.-BDMG',
    CNPJ: '38486817',
    Atividade: 'BANCOS DE DESENVOLVIMENTO',
  },
  {
    Código: '43964',
    'Nome da instituição': 'PROSEFTUR CORRETORA DE CAMBIO S.A',
    CNPJ: '40333582',
    Atividade: 'CORRETORAS DE CÂMBIO',
  },
  {
    Código: '57396',
    'Nome da instituição': 'FOURTRADE CORRETORA DE CAMBIO LTDA.',
    CNPJ: '40353377',
    Atividade: 'CORRETORAS DE CÂMBIO',
  },
  {
    Código: '44107',
    'Nome da instituição': 'PRONANCE SOCIEDADE CORRETORA DE CAMBIO LTDA',
    CNPJ: '41560568',
    Atividade: 'CORRETORAS DE CÂMBIO',
  },
  {
    Código: '06495',
    'Nome da instituição': 'BANCO DE LA PROVINCIA DE BUENOS AIRES',
    CNPJ: '44189447',
    Atividade: 'BANCOS COMERCIAIS',
  },
  {
    Código: '00011',
    'Nome da instituição': 'MARMARIS CORRETORA DE CAMBIO LTDA.',
    CNPJ: '45056494',
    Atividade: 'CORRETORAS DE CÂMBIO',
  },
  {
    Código: '31710',
    'Nome da instituição': 'BANCO GENIAL S.A.',
    CNPJ: '45246410',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '06488',
    'Nome da instituição': 'JPMORGAN CHASE BANK, NATIONAL ASSOCIATION',
    CNPJ: '46518205',
    Atividade: 'BANCOS COMERCIAIS',
  },
  {
    Código: '37218',
    'Nome da instituição': 'BANCO ANDBANK (BRASIL) S.A.',
    CNPJ: '48795256',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '57006',
    'Nome da instituição': 'LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA.',
    CNPJ: '50579044',
    Atividade: 'CORRETORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '05250',
    'Nome da instituição': 'BCV - BANCO DE CRÉDITO E VAREJO S.A.',
    CNPJ: '50585090',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '57353',
    'Nome da instituição': 'BEXS CORRETORA DE CAMBIO S/A',
    CNPJ: '52937216',
    Atividade: 'CORRETORAS DE CÂMBIO',
  },
  {
    Código: '53082',
    'Nome da instituição': 'BANCO HSBC S.A.',
    CNPJ: '53518684',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '05213',
    'Nome da instituição': 'BANCO ARBI S.A.',
    CNPJ: '54403563',
    Atividade: 'BANCOS COMERCIAIS',
  },
  {
    Código: '37419',
    'Nome da instituição': 'INTESA SANPAOLO BRASIL S.A. - BANCO MÚLTIPLO',
    CNPJ: '55230916',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '05422',
    'Nome da instituição': 'BANCO SAFRA S.A.',
    CNPJ: '58160789',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '05630',
    'Nome da instituição': 'BANCO LETSBANK S.A.',
    CNPJ: '58497702',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '05224',
    'Nome da instituição': 'BANCO FIBRA S.A.',
    CNPJ: '58616418',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '05600',
    'Nome da instituição': 'BANCO LUSO BRASILEIRO S.A.',
    CNPJ: '59118133',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '05623',
    'Nome da instituição': 'BANCO PAN S.A.',
    CNPJ: '59285411',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '05655',
    'Nome da instituição': 'BANCO VOTORANTIM S.A.',
    CNPJ: '59588111',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '06479',
    'Nome da instituição': 'BANCO ITAUBANK S.A.',
    CNPJ: '60394079',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '07456',
    'Nome da instituição': 'BANCO MUFG BRASIL S.A.',
    CNPJ: '60498557',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '07464',
    'Nome da instituição': 'BANCO SUMITOMO MITSUI BRASILEIRO S.A.',
    CNPJ: '60518222',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '07341',
    'Nome da instituição': 'ITAÚ UNIBANCO S.A.',
    CNPJ: '60701190',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '05237',
    'Nome da instituição': 'BANCO BRADESCO S.A.',
    CNPJ: '60746948',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '31123',
    'Nome da instituição': 'BANCO ALFA DE INVESTIMENTO S.A.',
    CNPJ: '60770336',
    Atividade: 'BANCOS DE INVESTIMENTO',
  },
  {
    Código: '05637',
    'Nome da instituição': 'BANCO SOFISA S.A.',
    CNPJ: '60889128',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '05653',
    'Nome da instituição': 'BANCO VOITER S.A.',
    CNPJ: '61024352',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '31111',
    'Nome da instituição': 'BANCO CREFISA S.A.',
    CNPJ: '61033106',
    Atividade: 'BANCOS COMERCIAIS',
  },
  {
    Código: '06370',
    'Nome da instituição': 'BANCO MIZUHO DO BRASIL S.A.',
    CNPJ: '61088183',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '05318',
    'Nome da instituição': 'BANCO BMG S.A.',
    CNPJ: '61186680',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '57346',
    'Nome da instituição':
      'AVENUE SECURITIES DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    CNPJ: '61384004',
    Atividade: 'DISTRIBUIDORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '40783',
    'Nome da instituição': 'SAGITUR CORRETORA DE CAMBIO S.A.',
    CNPJ: '61444949',
    Atividade: 'CORRETORAS DE CÂMBIO',
  },
  {
    Código: '07366',
    'Nome da instituição': 'BANCO SOCIETE GENERALE BRASIL S.A.',
    CNPJ: '61533584',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '05611',
    'Nome da instituição': 'BANCO PAULISTA S.A.',
    CNPJ: '61820817',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '06755',
    'Nome da instituição': 'BANK OF AMERICA MERRILL LYNCH BANCO MÚLTIPLO S.A.',
    CNPJ: '62073200',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '37420',
    'Nome da instituição':
      'STONEX DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    CNPJ: '62090873',
    Atividade: 'DISTRIBUIDORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '05643',
    'Nome da instituição': 'BANCO PINE S.A.',
    CNPJ: '62144175',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '31707',
    'Nome da instituição': 'BANCO DAYCOVAL S.A.',
    CNPJ: '62232889',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '37564',
    'Nome da instituição':
      'DIBRAN DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    CNPJ: '62280490',
    Atividade: 'DISTRIBUIDORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '57363',
    'Nome da instituição':
      'SINGULARE CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    CNPJ: '62285390',
    Atividade: 'CORRETORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '06487',
    'Nome da instituição': 'DEUTSCHE BANK S.A. - BANCO ALEMAO',
    CNPJ: '62331228',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '57698',
    'Nome da instituição': 'GUIDE INVESTIMENTOS S.A. CORRETORA DE VALORES',
    CNPJ: '65913436',
    Atividade: 'CORRETORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '05633',
    'Nome da instituição': 'BANCO RENDIMENTO S.A.',
    CNPJ: '68900810',
    Atividade: 'BANCOS COMERCIAIS',
  },
  {
    Código: '05218',
    'Nome da instituição': 'BANCO BS2 S.A.',
    CNPJ: '71027866',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '38009',
    'Nome da instituição':
      'LASTRO RDV DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    CNPJ: '71590442',
    Atividade: 'DISTRIBUIDORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '31824',
    'Nome da instituição': 'FRENTE CORRETORA DE CAMBIO S.A.',
    CNPJ: '71677850',
    Atividade: 'CORRETORAS DE CÂMBIO',
  },
  {
    Código: '38005',
    'Nome da instituição':
      'PACIFIC INVEST DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA',
    CNPJ: '73279093',
    Atividade: 'DISTRIBUIDORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '57434',
    'Nome da instituição': 'EXIM CORRETORA DE CAMBIO LTDA',
    CNPJ: '73302408',
    Atividade: 'CORRETORAS DE CÂMBIO',
  },
  {
    Código: '57440',
    'Nome da instituição': 'B&T CORRETORA DE CAMBIO LTDA.',
    CNPJ: '73622748',
    Atividade: 'CORRETORAS DE CÂMBIO',
  },
  {
    Código: '07753',
    'Nome da instituição': 'NOVO BANCO CONTINENTAL S.A. - BANCO MÚLTIPLO',
    CNPJ: '74828799',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '07222',
    'Nome da instituição': 'BANCO CRÉDIT AGRICOLE BRASIL S.A.',
    CNPJ: '75647891',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '57096',
    'Nome da instituição': 'DOURADA CORRETORA DE CAMBIO LTDA.',
    CNPJ: '76641497',
    Atividade: 'CORRETORAS DE CÂMBIO',
  },
  {
    Código: '57100',
    'Nome da instituição':
      'DEBONI DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA',
    CNPJ: '77162881',
    Atividade: 'DISTRIBUIDORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '31712',
    'Nome da instituição': 'BANCO OURINVEST S.A.',
    CNPJ: '78632767',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '57361',
    'Nome da instituição': 'CORREPARTI CORRETORA DE CAMBIO LTDA',
    CNPJ: '80202872',
    Atividade: 'CORRETORAS DE CÂMBIO',
  },
  {
    Código: '37080',
    'Nome da instituição':
      'EBADIVAL - E. BAGGIO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LT',
    CNPJ: '89784367',
    Atividade: 'DISTRIBUIDORAS DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    Código: '03008',
    'Nome da instituição': 'BANCO SANTANDER (BRASIL) S.A.',
    CNPJ: '90400888',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '04041',
    'Nome da instituição': 'BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.',
    CNPJ: '92702067',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '57017',
    'Nome da instituição': 'ADVANCED CORRETORA DE CAMBIO LTDA',
    CNPJ: '92856905',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
  {
    Código: '05212',
    'Nome da instituição': 'BANCO ORIGINAL S.A.',
    CNPJ: '92894922',
    Atividade: 'BANCOS MULTIPLOS COM CARTEIRA COMERCIAL',
  },
  {
    Código: '57423',
    'Nome da instituição': 'EFX CORRETORA DE CAMBIO LTDA.',
    CNPJ: '94968518',
    Atividade: 'CORRETORAS DE CâMBIO',
  },
];
