import React, { useState } from 'react';
import * as XLSX from 'xlsx/xlsx.mjs';

import {
  Button,
  Card,
  ChevronLeftIcon,
  ChevronRightIcon,
  CrossIcon,
  Heading,
  IconButton,
  Pane,
  RadioGroup,
  TextInput,
} from 'evergreen-ui';
import {
  convertDate2,
  firstDay,
  lastDay,
  monthsArray,
} from '../../helpers/dateConverter';
import { useDispatch, useSelector } from 'react-redux';
import { dateFilterActions } from '../../store/dateFilter';
import { useQueryEntriesFilteredByDate } from '../../services/api/entriesService';
import { convertDate3, convertDate } from '../../helpers/dateConverter';
import { mutate } from 'swr';
import { useEffect } from 'react';
import { formatToMoneyWithSymbol } from '../../helpers/moneyFormatter';
import { setMinutes } from 'date-fns';

function EntryJournalApiFilterBar({ tableProps }) {
  const companyId = localStorage.getItem('companyId');
  const companyName = localStorage.getItem('companyName');

  const dispatch = useDispatch();
  const startDate = useSelector((state) => state.dateFilter.startDate);
  const endDate = useSelector((state) => state.dateFilter.endDate);
  const startDateTemp = useSelector((state) => state.dateFilter.startDateTemp);
  const endDateTemp = useSelector((state) => state.dateFilter.endDateTemp);
  const filterType = useSelector((state) => state.dateFilter.filterType);
  const filterYear = useSelector((state) => state.dateFilter.filterYear);

  const [currentMonthIndex, setCurrentMonthIndex] = useState(
    new Date().getMonth()
  );

  const {
    data: journalEntriesFilteredByDate,
    isLoading: isLoadingJournalEntriesFilteredByDate,
  } = useQueryEntriesFilteredByDate(
    // company.data.id,
    companyId,
    startDate,
    endDate,
    filterType,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const nextMonth = () => {
    let tempYear = filterYear;
    if (currentMonthIndex === 11) {
      tempYear++;
      dispatch(dateFilterActions.increaseYear());
      dispatch(dateFilterActions.setStartDate(firstDay(0, tempYear)));
      dispatch(dateFilterActions.setEndDate(lastDay(0, tempYear)));
      dispatch(dateFilterActions.setStartDateTemp(firstDay(0, tempYear)));
      dispatch(dateFilterActions.setEndDateTemp(lastDay(0, tempYear)));
      setCurrentMonthIndex(0);
    } else {
      dispatch(
        dateFilterActions.setStartDate(
          firstDay(currentMonthIndex + 1, filterYear)
        )
      );
      dispatch(
        dateFilterActions.setEndDate(lastDay(currentMonthIndex + 1, filterYear))
      );
      dispatch(
        dateFilterActions.setStartDateTemp(
          firstDay(currentMonthIndex + 1, filterYear)
        )
      );
      dispatch(
        dateFilterActions.setEndDateTemp(
          lastDay(currentMonthIndex + 1, filterYear)
        )
      );
      setCurrentMonthIndex((old) => (old += 1));
    }
  };
  const previousMonth = () => {
    let tempYear = filterYear;
    if (currentMonthIndex === 0) {
      tempYear--;
      dispatch(dateFilterActions.decreaseYear());
      dispatch(dateFilterActions.setStartDate(firstDay(11, tempYear)));
      dispatch(dateFilterActions.setEndDate(lastDay(11, tempYear)));
      dispatch(dateFilterActions.setStartDateTemp(firstDay(11, tempYear)));
      dispatch(dateFilterActions.setEndDateTemp(lastDay(11, tempYear)));
      setCurrentMonthIndex(11);
    } else {
      dispatch(
        dateFilterActions.setStartDate(
          firstDay(currentMonthIndex - 1, filterYear)
        )
      );
      dispatch(
        dateFilterActions.setEndDate(lastDay(currentMonthIndex - 1, filterYear))
      );
      dispatch(
        dateFilterActions.setStartDateTemp(
          firstDay(currentMonthIndex - 1, filterYear)
        )
      );
      dispatch(
        dateFilterActions.setEndDateTemp(
          lastDay(currentMonthIndex - 1, filterYear)
        )
      );
      setCurrentMonthIndex((old) => (old -= 1));
    }
  };

  useEffect(() => {
    mutate('entriesFilteredByDate');
  }, [startDate, endDate, filterType]);

  const handleExport = () => {
    console.log(tableProps.getFilteredRowModel());
    const entriesToExport = [];
    tableProps.getFilteredRowModel().flatRows.map((row) => {
      if (Number.isInteger(parseInt(row.id))) {
        const tempComp = new Date(row.original.dateCompetencia);
        const tempVenc = new Date(row.original.dateVencimento);
        tempComp.setDate(tempComp.getDate() + 1);
        tempVenc.setDate(tempVenc.getDate() + 1);
        // tempVenc.setMinutes(1);
        entriesToExport.push({
          Tipo:
            row.original.journalEntryType === 5
              ? row.original.isInput
                ? row.original.entryType + ' (entrada)'
                : row.original.entryType + ' (saída)'
              : row.original.entryType,
          Status: row.original.statusString,
          Criado: new Date(row.original.dateCreate),
          Atualizado: new Date(row.original.dateUpdate),
          'Criado por': row.original.userUpdateId,
          Competência: tempComp,
          Vencimento: tempVenc,
          Conta: row.original.bankAccountName,
          'Classificação na DRE': row.original.dreClassification,
          Agrupamento: row.original.dreGroupingName,
          'Plano de Conta': row.original.accountPlanName,
          Categoria: row.original.categoryName,
          Descrição: row.original.description,
          Juros: row.original.interestValue,
          Multa: row.original.fineValue,
          Desconto: row.original.discountValue,
          Valor: row.original.value,
          'Valor Final': row.original.valueFinal,
        });
      }
    });
    console.log(entriesToExport);
    // console.log(journalEntriesFilteredByDate.data);

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert the JSON data to a worksheet
    const ws = XLSX.utils.json_to_sheet(entriesToExport);
    // XLSX.utils.sheet_add_aoa(
    //   ws,
    //   [
    //     [
    //       'Classificação na DRE',
    //       'Agrupamento',
    //       'Plano de Conta',
    //     ],
    //   ],
    //   {
    //     origin: 'G1',
    //   }
    // );
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Lançamentos');

    // Generate a download link and click it
    XLSX.writeFile(
      wb,
      `lancs_${companyName}_${convertDate2(startDate).replaceAll(
        '/',
        '-'
      )}_${convertDate2(endDate).replaceAll('/', '-')}.xlsx`
      // { cellDates: true }
    );
  };

  const dateFilterHandler = () => {
    dispatch(dateFilterActions.setStartDate(startDateTemp));
    dispatch(dateFilterActions.setEndDate(endDateTemp));
  };

  return (
    <Card
      className='cardApiFilter'
      minHeight={64}
      display='flex'
      position='relative'
      alignItems='center'
      gap={16}
      padding={16}
      flexDirection='row'
      flexWrap='wrap'
      width='100%'
      backgroundColor='#f4f5f9'
      justifyContent='center'
    >
      <Pane
        display='flex'
        alignItems='center'
        flexWrap='wrap'
        justifyContent='center'
      >
        <Pane
          display='flex'
          alignItems='center'
          paddingRight={16}
          height='100%'
          gap={6}
        >
          <TextInput
            id='startDate'
            name='startDate'
            type='date'
            width='120px'
            value={startDateTemp}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                dispatch(dateFilterActions.setStartDate(startDateTemp));
                dispatch(dateFilterActions.setEndDate(endDateTemp));
              }
            }}
            onChange={(e) => {
              dispatch(dateFilterActions.setStartDateTemp(e.target.value));
            }}
          />
          <TextInput
            id='endDate'
            name='endDate'
            type='date'
            width='120px'
            value={endDateTemp}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                dispatch(dateFilterActions.setStartDate(startDateTemp));
                dispatch(dateFilterActions.setEndDate(endDateTemp));
              }
            }}
            onChange={(e) => {
              dispatch(dateFilterActions.setEndDateTemp(e.target.value));
              console.log(endDateTemp);
            }}
          />

          {startDateTemp ===
            firstDay(new Date().getMonth(), new Date().getFullYear()) &&
          endDateTemp ===
            lastDay(new Date().getMonth(), new Date().getFullYear()) ? null : (
            <IconButton
              marginLeft={2}
              icon={CrossIcon}
              appearance='minimal'
              size='small'
              intent='danger'
              onClick={() => {
                setCurrentMonthIndex(new Date().getMonth());
                dispatch(dateFilterActions.resetFilter());
              }}
            />
          )}
        </Pane>
        <Pane>
          <Button
            appearance='primary'
            maxWidth='fit-content'
            size='medium'
            onClick={dateFilterHandler}
            disabled={startDate === startDateTemp && endDate === endDateTemp}
          >
            Filtrar
          </Button>
        </Pane>
        <Pane paddingLeft={16}>
          <RadioGroup
            value={filterType}
            options={[
              {
                label: 'Competência',
                value: 'dateCompetencia',
              },
              {
                label: 'Vencimento',
                value: 'dateVencimento',
              },
            ]}
            onChange={(e) => {
              dispatch(dateFilterActions.setFilterType(e.target.value));
              mutate('entriesFilteredByDate');
            }}
          />
        </Pane>
      </Pane>

      <Pane
        display='flex'
        alignItems='center'
        justifySelf='center'
        width='fit-content'
      >
        <IconButton
          icon={ChevronLeftIcon}
          appearance='minimal'
          onClick={previousMonth}
          size='large'
          marginRight={8}
        />

        {Number(startDate.substr(5, 2)) === currentMonthIndex + 1 &&
        Number(endDate.substr(5, 2)) === currentMonthIndex + 1 ? (
          <Heading>{monthsArray[currentMonthIndex]}</Heading>
        ) : Number(startDate.substr(5, 2)) === Number(endDate.substr(5, 2)) ? (
          setCurrentMonthIndex(Number(startDate.substr(5, 2)) - 1)
        ) : (
          <Heading>Personalizado</Heading>
        )}

        <IconButton
          icon={ChevronRightIcon}
          appearance='minimal'
          onClick={nextMonth}
          size='large'
          marginLeft={8}
        />
      </Pane>
      <Pane>
        <Button
          appearance='primary'
          intent='success'
          isLoading={isLoadingJournalEntriesFilteredByDate}
          onClick={handleExport}
        >
          Exportar
        </Button>
      </Pane>
    </Card>
  );
}
export default EntryJournalApiFilterBar;
