import { React, useEffect, useState } from 'react';

import { Button, Card, Heading, TextInputField } from 'evergreen-ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase-config';
import { toastNotification } from '../../shared/toastNotification';

export default function ResetPasswordCard() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const {
    errors,
    dirtyFields,
    isDirty,
    isSubmitting,
    touchedFields,
    submitCount,
  } = formState;

  const onSubmit = async (data) => {
    let statusResponse = false;
    let toastContent = '';
    let actionCodeSettings = {
      url: 'https://novo.vita.app.br',
    };
    sendPasswordResetEmail(auth, data.email, actionCodeSettings)
      .then(() => {
        statusResponse = 'success';
        toastContent = `Um link foi enviado para ${data.email} com informações para alterar a senha.`;
        setTimeout(() => {
          navigate('/');
        }, 1500);
      })
      .catch((error) => {
        if (error.code === 'auth/user-not-found') {
          statusResponse = 'error';
          toastContent = 'Usuário não encontrado.';
        } else if (error.code === 'auth/invalid-email') {
          statusResponse = 'error';
          toastContent = 'E-mail inválido.';
        } else if (error.code === 'auth/too-many-requests') {
          statusResponse = 'error';
          toastContent = 'Muitas tentativas. Tente mais tarde.';
        } else {
          statusResponse = 'error';
          toastContent = 'Erro ao enviar e-mail.';
        }
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
      });
  };

  return (
    <div className='content'>
      <Card
        elevation={4}
        float='left'
        background='white'
        margin={24}
        padding={16}
        display='flex'
        height='fit-content'
        flexDirection='column'
      >
        <Heading size={700} marginY={15}>
          Alterar Senha
        </Heading>
        <form id='signup-form' onSubmit={handleSubmit(onSubmit)}>
          <TextInputField
            {...register(
              'email',

              {
                pattern: {
                  value:
                    /^[\sa-zA-Z0-9._-]+@[a-zA-Z0-9]{2,}.[a-zA-Z]{2,}(.?[a-z\s]{2,})?$/gm,
                  message: 'Digite um e-mail válido.',
                },
              }
            )}
            minWidth={250}
            label='E-mail'
            type='text'
            validationMessage={errors?.email?.message}
            required
          />
        </form>
        <Button
          size='large'
          marginTop={12}
          isLoading={loading}
          // onClick={() => fetchLogin()}
          appearance='minimal'
          color='blue'
          form='signup-form'
          type='submit'
        >
          Resetar
        </Button>

        <Card
          display='flex'
          flexDirection='column'
          gap={0}
          justifyContent='space-between'
        ></Card>
        <style jsx='true'>{``}</style>
      </Card>
      <style jsx='true'>
        {`
          .content {
            display: flex;
            flex-direction: row-reverse;
            gap: 36px;
            height: 100%;
            width: 100%;
            background: linear-gradient(
              257.63deg,
              rgba(9, 153, 157, 0.25) -0.36%,
              rgba(59, 128, 177, 0.25) 14.31%,
              rgba(0, 36, 164, 0.25) 40.45%,
              rgba(169, 107, 231, 0.25) 88.1%,
              rgba(243, 72, 91, 0.25) 120.83%
            );
          }
        `}
      </style>
    </div>
  );
}
