import React, { useState, useRef, useEffect } from 'react';
import {
  Dialog,
  Heading,
  TextInputField,
  FormField,
  Button,
  Pane,
  Icon,
  PersonIcon,
  Badge,
  SmallTickIcon,
  IconButton,
  SelectField,
  CrossIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Switch,
  Spinner,
} from 'evergreen-ui';
import { Container, Row, Col, setConfiguration } from 'react-grid-system';

import { mutate } from 'swr';
import { useForm, Controller } from 'react-hook-form';
import { IMaskMixin } from 'react-imask';

import { toastNotification } from '../../shared/toastNotification';

import { inputEntryValidation } from '../../helpers/formHelper';
import {
  putVitaUser,
  useQueryVitaUserByIdentityId,
} from '../../services/api/vitaUserService';
import { useSelector } from 'react-redux';

function EditUserForm({ isShown, setIsShown }) {
  const userId = localStorage.getItem('userId');

  /* Dialog settings  */

  const [isDisabled, setIsDisabled] = useState(true);

  /* API calls */

  const {
    data: vitaUser,
    isLoading: isLoadingVitaUser,
    isValidating: isValidatingVitaUser,
    isError,
  } = useQueryVitaUserByIdentityId(userId, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  /* Form */

  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    control,
    watch,
  } = useForm({});

  setConfiguration({
    containerWidths: '100%',
    gutterWidth: '12',
  });

  const buildFormHeader = () => {
    return (
      <Pane display='flex' flex={1} justifyContent='space-between'>
        <Pane
          display='flex'
          flexDirection='row'
          flexWrap='nowrap'
          alignItems='center'
          gap={16}
        >
          <Icon icon={PersonIcon} color='selected' />
          <Heading size={700}>Editar usuário</Heading>
        </Pane>
        <IconButton appearance='minimal' icon={CrossIcon} onClick={onClose} />
      </Pane>
    );
  };

  const buildFormFooter = () => {
    return (
      <Pane display='flex' flex={1} justifyContent='flex-end'>
        <Button form='user-form' type='submit' appearance='primary' marginY={8}>
          Salvar
        </Button>
      </Pane>
    );
  };

  const onSubmit = () => {
    let statusResponse = false;
    let toastContent = '';

    let userToCreate = {
      name: getValues('name'),
      lastName: getValues('lastName'),
      email: vitaUser.data.email,
      identityUserId: vitaUser.data.identityUserId,
      // phoneNumber: getValues('phoneNumber'),
      id: vitaUser.data.id,
    };

    putVitaUser(userToCreate)
      .then((res) => {
        console.log('res post user: ', res);
        statusResponse = 'success';
        toastContent = 'Usuário salvo com sucesso.';
        mutate('vitaUserByIdentityId');
        onClose();
      })
      .catch((err) => {
        statusResponse = 'warning';
        console.log('post err', err);
        toastContent = `Verifique os campos novamente.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
      });
  };

  const MaskedInput = IMaskMixin(({ inputRef, ...props }) => {
    return (
      <TextInputField
        {...props}
        ref={inputRef} // bind internal input (if you use styled-components V4, use "ref" instead "innerRef")
      />
    );
  });

  const phoneMask = [{ mask: '(00) 0000-0000' }, { mask: '(00) 00000-0000' }];

  const onClose = () => {
    reset();
    setIsShown(false);
  };

  useEffect(() => {
    reset(vitaUser.data);
  }, [isValidatingVitaUser]);

  return (
    <Dialog
      isShown={isShown}
      title=''
      onCloseComplete={onClose}
      confirmLabel='Custom Label'
      hasFooter={true}
      header={buildFormHeader}
      footer={buildFormFooter}
      width={800}
      backgroundColor='#f8f8f8'
    >
      <div>
        <form id='user-form' onSubmit={handleSubmit(onSubmit)}>
          <FormField>
            <Container
              fluid={true}
              style={{
                width: '100%',
                margin: 0,
                padding: 0,
              }}
            >
              <Row>
                <Col sm={12} md={12} lg={12}>
                  <TextInputField
                    {...register('name')}
                    label='Nome'
                    id='name'
                    name='name'
                    type='text'
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} lg={12}>
                  <TextInputField
                    {...register('lastName')}
                    label='Sobrenome'
                    id='lastName'
                    name='lastName'
                    type='text'
                  />
                </Col>
              </Row>

              {/* <Row>
                <Col sm={12} md={12} lg={12}>
                  <Controller
                    control={control}
                    name='phoneNumber'
                    render={(props) => {
                      return (
                        <MaskedInput
                          label='Telefone'
                          id='phoneNumber'
                          onAccept={(val) => {
                            props.field.onChange(val);
                          }}
                          type='text'
                          mask={phoneMask}
                          unmask={true}
                          value={props.field.value}
                        />
                      );
                    }}
                  />
                </Col>
              </Row> */}
            </Container>
          </FormField>
        </form>
      </div>
    </Dialog>
  );
}

export default EditUserForm;
