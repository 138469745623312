import React from 'react';
import {
  CaretDownIcon,
  CaretUpIcon,
  DoubleCaretVerticalIcon,
  Heading,
  IconButton,
  Menu,
  MoreIcon,
  Pane,
  Popover,
  Position,
  Table,
  Text,
  TrashIcon,
} from 'evergreen-ui';

import { useDispatch, useSelector } from 'react-redux';
import { convertDate2 } from '../../helpers/dateConverter';
import { putManyEntry } from '../../services/api/entriesService';
import { toastNotification } from '../../shared/toastNotification';
import { mutate } from 'swr';
import { putStatement } from '../../services/api/statementService';

const ReconciliatedJournalEntryAccordion = ({
  entriesReconciliation,
  currentStatement,
  reconciliatedDateSorting,
  reconciliatedValueSorting,
  handleReconciliatedDateSorting,
  handleReconciliatedValueSorting,
}) => {
  const selectedFilter = useSelector(
    (state) => state.reconciliationValues.selectedFilter
  );

  const dispatch = useDispatch();

  const handleDisconciliation = (entry) => {
    let statusResponse = false;
    let toastContent = '';

    const entriesToDisconciliate = entriesReconciliation.data
      .filter((item) => item.fitId === entry.fitId)
      .map((item) => {
        return { ...item, fitId: null };
      });
    putManyEntry(entriesToDisconciliate)
      .then((response) => {
        statusResponse = 'success';
        toastContent = 'Desconciliação realizada com sucesso.';
      })
      .catch((error) => {
        statusResponse = 'warning';
        console.log('post err', error);
        toastContent = `Algo deu errado, tente novamente.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
        mutate('entriesReconciliation');
      });

    console.log(entriesToDisconciliate);
  };

  return (
    <Pane width='30%' marginX={5} marginBottom={0} paddingRight={5}>
      <Heading marginY={5} textAlign='center'>
        Lançamentos Conciliados
      </Heading>
      <Pane textAlign='center'>
        <Table>
          <Table.Head height={40}>
            <Table.TextHeaderCell>
              <Pane
                display='flex'
                alignItems='center'
                justifyContent='center'
                onClick={handleReconciliatedDateSorting}
                cursor='pointer'
              >
                Data
                {reconciliatedDateSorting === 'asc' ? (
                  <CaretUpIcon color='#3366FF' />
                ) : reconciliatedDateSorting === 'desc' ? (
                  <CaretDownIcon color='#3366FF' />
                ) : (
                  <DoubleCaretVerticalIcon color='#3366FF' />
                )}
              </Pane>
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>Descrição</Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <Pane
                display='flex'
                alignItems='center'
                justifyContent='center'
                onClick={handleReconciliatedValueSorting}
                cursor='pointer'
              >
                Valor
                {reconciliatedValueSorting === 'asc' ? (
                  <CaretUpIcon color='#3366FF' />
                ) : reconciliatedValueSorting === 'desc' ? (
                  <CaretDownIcon color='#3366FF' />
                ) : (
                  <DoubleCaretVerticalIcon color='#3366FF' />
                )}
              </Pane>
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>Ações</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            <Pane overflowY='scroll' maxHeight={130}>
              {entriesReconciliation &&
                currentStatement.statementObject &&
                entriesReconciliation.data
                  .filter(
                    (entry) =>
                      entry.journalEntryType === 1 ||
                      entry.journalEntryType === 5
                  )
                  .filter(
                    (entry) => entry.bankAccountId === currentStatement.bankId
                  )
                  .filter((entry) => {
                    if (selectedFilter === 'todos') {
                      return entry;
                    } else if (selectedFilter === 'entrada') {
                      return entry.isInput === true;
                    } else if (selectedFilter === 'saida') {
                      return entry.isInput === false;
                    }
                  })
                  .filter((entry) =>
                    currentStatement.statementObject.data.transactions.some(
                      (statement) => statement.FITID === entry.fitId
                    )
                  )
                  .sort((a, b) => {
                    if (reconciliatedDateSorting === 'asc') {
                      return (
                        new Date(a.dateVencimento) - new Date(b.dateVencimento)
                      );
                    } else if (reconciliatedDateSorting === 'desc') {
                      return (
                        new Date(b.dateVencimento) - new Date(a.dateVencimento)
                      );
                    } else {
                      return 0;
                    }
                  })
                  .sort((a, b) => {
                    if (reconciliatedValueSorting === 'asc') {
                      return a.valueFinal - b.valueFinal;
                    } else if (reconciliatedValueSorting === 'desc') {
                      return b.valueFinal - a.valueFinal;
                    } else {
                      return 0;
                    }
                  })
                  .map((entry) => {
                    console.log('entryAcc', entry);
                    return (
                      <Table.Row key={entry.id} height={45}>
                        <Table.TextCell>
                          <Text>{convertDate2(entry.dateVencimento)}</Text>
                        </Table.TextCell>
                        <Table.TextCell>
                          <Text>{entry.description || '-'}</Text>
                        </Table.TextCell>
                        <Table.TextCell>
                          <Text
                            color={
                              entry.journalEntryType === 1
                                ? entry.isInput
                                  ? 'blue'
                                  : 'red'
                                : 'green'
                            }
                          >
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(entry.valueFinal)}
                          </Text>
                        </Table.TextCell>
                        <Table.TextCell>
                          <Pane onClick={(e) => e.stopPropagation()}>
                            <Popover
                              position={Position.BOTTOM_LEFT}
                              animationDuration={1}
                              key={entry.id}
                              content={
                                <Menu>
                                  <Menu.Group>
                                    <Menu.Item
                                      icon={TrashIcon}
                                      intent='danger'
                                      onClick={() =>
                                        handleDisconciliation(entry)
                                      }
                                    >
                                      Desconciliar
                                    </Menu.Item>
                                  </Menu.Group>
                                </Menu>
                              }
                            >
                              <IconButton
                                appearance='minimal'
                                intent='info'
                                icon={MoreIcon}
                              />
                            </Popover>
                          </Pane>
                        </Table.TextCell>
                      </Table.Row>
                    );
                  })}
            </Pane>
          </Table.Body>
        </Table>
      </Pane>
    </Pane>
  );
};

export default ReconciliatedJournalEntryAccordion;
