import { Routes, Route } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.min.css';

import PrivateRoute from './PrivateRoute';

import LandPage from '../components/landpage/LandPage';
import LayoutPage from '../components/layout/LayoutPage';
import DashboardPage from '../components/dashboard/DashboardPage';
import CalendarPage from '../components/calendar/CalendarPage';
import CategoriesFamilyPage from '../components/categories-family/CategoriesFamilyPage';
import BankAccountsPage from '../components/bank-accounts/BankAccountsPage';
import SwitchCompanyPage from '../components/switch-company/SwitchCompanyPage';
import FirstAccessPage from '../components/first-access/FirstAccessPage';
import EntryJournalsPage from '../components/entries/EntryJournalsPage';
import NotFoundPage from '../components/NotFoundPage';
import MyAccountPage from '../components/my-account/MyAccountPage';
import DrePage from '../components/dre/DrePage';
import ReconciliationPage from '../components/reconciliation/ReconciliationPage';
import ResetPasswordPage from '../components/landpage/ResetPasswordPage';
import MyCompanyPage from '../components/company/MyCompanyPage';
import TermsAndConditionsPage from '../components/first-access/TermsAndConditionsPage';

function MyRoutesTest() {
  return (
    <Routes>
      <Route path='*' element={<NotFoundPage />} />
      <Route path='/' element={<LandPage />} />

      {/* <Route
        // <PrivateRoute>
        path='primeiro-acesso'
        element={<FirstAccessPage />}
        // </PrivateRoute>
      /> */}
      <Route
        // <PrivateRoute>
        path='termos-de-uso'
        element={<TermsAndConditionsPage />}
        // </PrivateRoute>
      />

      <Route
        path='alterar-senha'
        element={
          // <PrivateRoute>
          <ResetPasswordPage />
          // </PrivateRoute>
        }
      />
      <Route
        path='empresas'
        element={
          // <PrivateRoute>
          <SwitchCompanyPage />
          // </PrivateRoute>
        }
      />
      <Route
        path='/'
        element={
          // <PrivateRoute>
          <LayoutPage />
          // </PrivateRoute>
        }
      >
        <Route
          path='lancamentos'
          element={
            <PrivateRoute
              roles={[
                'LANÇADOR',
                'ANALISTA',
                'ADMIN',
                'CONSULTOR',
                'SUPER_ADMIN',
              ]}
            >
              <EntryJournalsPage />
            </PrivateRoute>
          }
        />
        <Route
          path='declaracao'
          element={
            <PrivateRoute
              roles={['ADMIN', 'CONSULTOR', 'ANALISTA', 'SUPER_ADMIN']}
            >
              <DrePage />
            </PrivateRoute>
          }
        />
        <Route path='minha-conta' element={<MyAccountPage />}></Route>
        <Route
          path='minha-empresa'
          element={
            <PrivateRoute roles={['ADMIN', 'CONSULTOR', 'SUPER_ADMIN']}>
              <MyCompanyPage />
            </PrivateRoute>
          }
        />
        <Route
          path='dashboard'
          element={
            <PrivateRoute
              roles={['ADMIN', 'SUPER_ADMIN', 'CONSULTOR', 'ANALISTA']}
            >
              <DashboardPage />
            </PrivateRoute>
          }
        />
        <Route
          path='calendario'
          element={
            <PrivateRoute
              roles={[
                'ADMIN',
                'SUPER_ADMIN',
                'CONSULTOR',
                'ANALISTA',
                'LANÇADOR',
              ]}
            >
              <CalendarPage />
            </PrivateRoute>
          }
        />
        <Route
          path='categorias'
          element={
            <PrivateRoute
              roles={['ADMIN', 'SUPER_ADMIN', 'CONSULTOR', 'LANÇADOR']}
            >
              <CategoriesFamilyPage />
            </PrivateRoute>
          }
        />
        <Route
          path='contas'
          element={
            <PrivateRoute
              roles={['ADMIN', 'SUPER_ADMIN', 'CONSULTOR', 'LANÇADOR']}
            >
              <BankAccountsPage />
            </PrivateRoute>
          }
        />
        <Route
          path='conciliacao'
          element={
            <PrivateRoute
              roles={['ADMIN', 'SUPER_ADMIN', 'CONSULTOR', 'LANÇADOR']}
            >
              <ReconciliationPage />
            </PrivateRoute>
          }
        />
      </Route>
    </Routes>
  );
}

export default MyRoutesTest;
