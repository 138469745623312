export const vitaUsers = [
  {
    name: 'Júlia',
    lastName: 'Firjam Torres',
    email: 'juliafirjamtorres@gmail.com',
    identityUserId: 'e6a4adae-f1c0-4ae5-91c8-a7e1f27f7b7f',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '8208628f-2221-4b59-9659-e1834d3113a1',
    dateCreate: '2023-07-12T16:52:01.651923',
    dateUpdate: '2023-07-12T16:52:01.652163',
  },
  {
    name: 'Magnus',
    lastName: ' ',
    email: 'financeiro@magnus.org.br',
    identityUserId: '644cdfc5-d771-41c5-8c95-4ddd25cb7d7c',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: 'fbc401f1-c16d-4445-b327-4950442d1eee',
    dateCreate: '2022-05-02T00:00:00',
    dateUpdate: '2022-05-02T00:00:00',
  },
  {
    name: 'Matheus',
    lastName: 'Polidoro',
    email: 'matheusjcp@gmail.com',
    identityUserId: 'de6261c9-5416-4979-b963-175092de8b0a',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '1b40aee5-a019-472a-a695-8020da537a62',
    dateCreate: '2023-03-16T18:02:01.814284',
    dateUpdate: '2023-03-16T18:02:01.814287',
  },
  {
    name: 'GISLAINE',
    lastName: 'BARBOSA',
    email: 'societario@agiumcontabilidade.com.br',
    identityUserId: 'fb145dda-2e3e-4200-824b-b01ae40fffcb',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '2a3d90b3-6119-4f37-b6b0-714198e390eb',
    dateCreate: '2023-10-26T15:49:13.829208',
    dateUpdate: '2023-10-26T15:49:13.829209',
  },
  {
    name: 'Jovana',
    lastName: 'Rodrigues',
    email: 'deliciasdajorodrigues@outlook.com',
    identityUserId: 'f9600d13-b90d-431f-a14f-e8e63776a6dd',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '47954ea4-8201-49a9-86f9-093467508486',
    dateCreate: '2023-05-22T00:00:00',
    dateUpdate: '2023-05-22T00:00:00',
  },
  {
    name: 'Gaya Pratas',
    lastName: 'Pratas',
    email: 'gayapratas@hotmail.com',
    identityUserId: '74d09bf4-cd62-4335-8d2e-1766e701cae6',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: 'a07fd6e4-7a07-459f-a002-611f026614ba',
    dateCreate: '2023-06-19T11:08:35.884202',
    dateUpdate: '2023-06-19T11:08:35.884203',
  },
  {
    name: 'Adriane',
    lastName: 'Trogo',
    email: 'adriane.jf@hotmail.com',
    identityUserId: 'b95aea4a-3e21-4282-9893-c97629bea801',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '7f11296a-9713-4f27-ad9b-cf97aeadd301',
    dateCreate: '2023-06-21T13:56:12.251277',
    dateUpdate: '2023-06-21T13:56:12.251279',
  },
  {
    name: 'MATHEUS',
    lastName: 'AMORIM',
    email: 'matheus.cardinelli@engenharia.ufjf.br',
    identityUserId: 'bc0306e3-37cc-4132-aee4-2dfb4b6e41ed',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '58cea27f-a3bb-4a63-9b7e-1e5103d6202f',
    dateCreate: '2023-08-15T15:58:45.934206',
    dateUpdate: '2023-08-15T15:58:45.934395',
  },
  {
    name: 'Hiara Soares Rodrigues',
    lastName: ' ',
    email: 'hiararodriguesjf.rodrigues@gmail.com',
    identityUserId: 'bfa9720e-67b3-4756-88cd-775c9bc994d3',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '05871002-b5b5-43be-bf00-dda5b9f6c178',
    dateCreate: '2023-05-22T00:00:00',
    dateUpdate: '2023-05-22T00:00:00',
  },
  {
    name: 'vanderleia ',
    lastName: ' ',
    email: 'mobiliadora.nova@yahoo.com.br',
    identityUserId: 'c90fc510-7ed4-4ed8-9218-41a6df17497e',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '14fb3090-9997-4403-b989-993b12d47879',
    dateCreate: '2023-06-02T00:00:00',
    dateUpdate: '2023-06-02T00:00:00',
  },
  {
    name: 'Lider Instalações',
    lastName: ' ',
    email: 'debbys.conde@hotmail.com',
    identityUserId: '257c7e31-aac8-4f07-9696-6538b65c7c15',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: 'c5897f06-0b8f-4bb6-a964-c1b00ab8f1d2',
    dateCreate: '2022-12-07T00:00:00',
    dateUpdate: '2022-12-07T00:00:00',
  },
  {
    name: 'Paula Serrano',
    lastName: 'Cupcakes',
    email: 'cupcakesdapaulajf@gmail.com',
    identityUserId: '39380ef3-6603-484b-b574-fcae0de55d7a',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: 'ee889987-cd8b-421e-8c5c-79d13237d115',
    dateCreate: '2023-08-25T08:37:54.113218',
    dateUpdate: '2023-08-25T08:37:54.113219',
  },
  {
    name: 'Adriane',
    lastName: 'Trogo',
    email: 'adriane.trogo@engenharia.ufjf.br',
    identityUserId: 'a335403e-437a-43f3-9fb7-332fee52a754',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '2158ccce-4b5a-49de-9b68-f59d0e39d207',
    dateCreate: '2023-09-30T18:33:46.841519',
    dateUpdate: '2023-09-30T18:33:46.841705',
  },
  {
    name: 'HAG - Experiência Digital',
    lastName: ' ',
    email: 'aureliogla@gmail.com',
    identityUserId: '29d203d8-ad76-4871-afcf-7af93e244915',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: 'f61fb710-c8fa-4125-9dba-a86abfc470a0',
    dateCreate: '2023-01-13T00:00:00',
    dateUpdate: '2023-01-13T00:00:00',
  },
  {
    name: 'Carolina',
    lastName: 'Gomes',
    email: 'carolyooho@hotmail.com',
    identityUserId: 'efa8934f-bbfd-4a6f-955e-4722985d5854',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: 'c3cb8bb2-d924-4fc3-9670-b995e7defc8d',
    dateCreate: '2023-10-05T14:29:37.331938',
    dateUpdate: '2023-10-05T14:29:37.33194',
  },
  {
    name: 'Wanessa Varela da Silva correa ',
    lastName: ' ',
    email: 'wanessavarela@yahoo.com.br',
    identityUserId: 'e83b1934-387b-466a-ae2d-3d9f8b61fc1b',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '1f06cb91-6d33-4b2a-aab3-35fc9911975e',
    dateCreate: '2023-05-08T00:00:00',
    dateUpdate: '2023-05-08T00:00:00',
  },
  {
    name: 'Victor',
    lastName: ' ',
    email: 'financeiro@lavanderiamoreira.com',
    identityUserId: '4478d3f7-af22-498f-bea3-fa555ac19704',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '88d401bc-1f6f-4571-b0be-6e7016021750',
    dateCreate: '2023-04-10T00:00:00',
    dateUpdate: '2023-04-10T00:00:00',
  },
  {
    name: 'Andréa ',
    lastName: 'Pucci',
    email: 'deka38@hotmail.com',
    identityUserId: '4c8aa69c-4862-4200-8af3-9f9938b79df5',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '5362aabf-500d-4659-9342-d17c74347df1',
    dateCreate: '2023-09-25T09:50:57.541078',
    dateUpdate: '2023-09-25T09:50:57.541079',
  },
  {
    name: 'Pábio Alexandre',
    lastName: 'Dos Santos Ribeiro',
    email: 'ondupeltabuasparabolo@hotmail.com',
    identityUserId: '3ef4f9b7-54a5-4869-bcf2-404b1574bdbe',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '37a37576-02a7-4859-a8bb-5145aeb9b4c6',
    dateCreate: '2023-09-26T08:39:33.914046',
    dateUpdate: '2023-09-26T08:39:33.914048',
  },
  {
    name: 'Márcio',
    lastName: 'Rodrigues',
    email: 'marciocarminha@hotmail.com',
    identityUserId: 'fc729854-548a-49c4-9a33-727590859da8',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '9910ef98-f7ae-4f3e-a1d6-b67bebc8b858',
    dateCreate: '2023-09-30T11:18:00.423761',
    dateUpdate: '2023-09-30T11:18:00.423762',
  },
  {
    name: 'Leonardo',
    lastName: 'Motta',
    email: 'borabrasil.ne@gmail.com',
    identityUserId: '82bd7495-a6e8-4516-8f33-615ff4fda0ad',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '16cf1115-d5e2-46d5-8056-d0aefb1dfd2d',
    dateCreate: '2023-10-04T09:32:49.574372',
    dateUpdate: '2023-10-04T09:32:49.574374',
  },
  {
    name: 'Geysa',
    lastName: 'Borelli',
    email: 'michaelsilvajf23@hotmail.com',
    identityUserId: 'c24749eb-5dfe-4b7d-a4d2-3c17e96c6542',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: 'd0fcfb69-928c-43c4-b906-e98a71d9fb55',
    dateCreate: '2023-09-21T10:53:11.104667',
    dateUpdate: '2023-09-21T10:53:11.104668',
  },
  {
    name: 'Pedro',
    lastName: 'Itabirano',
    email: 'pedroxitabirano@gmail.com',
    identityUserId: 'a597ebea-f81c-4006-bdde-896b55527944',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '17dac1c9-c1c6-4466-b73f-1dca1ccd980f',
    dateCreate: '2023-10-06T15:08:53.533745',
    dateUpdate: '2023-10-06T15:08:53.533747',
  },
  {
    name: 'Rita de Oliveira ',
    lastName: 'Andrade',
    email: 'quinhaandrade@terra.com.br',
    identityUserId: '0082e7b6-95e5-4214-b5ee-5c42b2aeb5a3',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '65b2a6b5-df74-47f8-a577-4ee70646f036',
    dateCreate: '2023-10-13T15:28:41.946106',
    dateUpdate: '2023-10-13T15:28:41.946107',
  },
  {
    name: 'Rita de Oliveira ',
    lastName: 'Andrade',
    email: 'quinhaandrade@terra.com.brr',
    identityUserId: '0082e7b6-95e5-4214-b5ee-5c42b2aeb5a3',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '81762eaf-bd57-42a0-9918-ee24831ea064',
    dateCreate: '2023-10-13T15:28:41.946102',
    dateUpdate: '2023-10-13T15:28:41.946103',
  },
  {
    name: 'Teto arquitetura',
    lastName: ' ',
    email: 'roberta@tetoarquitetura.com',
    identityUserId: '24a2b52f-15c9-4043-bbab-601690d24cfd',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '347f1433-bd54-4a11-9d46-784997f08f76',
    dateCreate: '2021-10-27T00:00:00',
    dateUpdate: '2021-10-27T00:00:00',
  },
  {
    name: 'Talita',
    lastName: 'Del-Penho',
    email: 'talitadelpenho@gmail.com',
    identityUserId: '6fc32df4-a817-48e2-8f85-e06b0293f353',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: 'ef9e5f92-ab25-4efd-b010-1e697063d140',
    dateCreate: '2023-11-03T09:17:44.413811',
    dateUpdate: '2023-11-03T09:17:44.413812',
  },
  {
    name: 'alessandra',
    lastName: ' ',
    email: 'oficinamonoblocojf@hotmail.com',
    identityUserId: '484669dc-6777-4fc7-8959-37f310f77eb9',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: 'fbc0e570-44a7-42ba-8716-fbff72b0c26f',
    dateCreate: '2021-10-27T00:00:00',
    dateUpdate: '2021-10-27T00:00:00',
  },
  {
    name: 'MATHEUS',
    lastName: 'AMORIM',
    email: 'cardinellimatheus@gmail.com',
    identityUserId: '4e28a343-e04a-4e9d-b0b1-f7501cf39bcb',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: 'd1356882-6d92-4dca-97f9-f97e6e891fcc',
    dateCreate: '2023-08-28T15:23:34.049083',
    dateUpdate: '2023-08-28T15:23:34.049084',
  },
  {
    name: 'Kássio',
    lastName: 'Policarpo',
    email: 'kassiospolicarpo@gmail.com',
    identityUserId: 'f6e211d1-e93c-4047-8823-d58dac51a3d7',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: 'b07775f9-27a2-428c-afb4-95825b259f21',
    dateCreate: '2023-03-31T07:41:05.787382',
    dateUpdate: '2023-03-31T07:41:05.787591',
  },
  {
    name: 'Luciana ',
    lastName: 'Chagas',
    email: 'budegadopapai@gmail.com',
    identityUserId: 'ae7988a6-7d0c-481d-bbaa-3f7cf9e2c5c3',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '91fb2fc7-6b27-4ab4-9a9f-3420d915c289',
    dateCreate: '2023-11-21T18:58:39.483185',
    dateUpdate: '2023-11-21T18:58:39.483188',
  },
  {
    name: 'Janine',
    lastName: ' O',
    email: 'premoldadospef@gmail.com',
    identityUserId: '87fc7080-c526-4736-8886-885ed2824d45',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: 'f7c86c7e-2477-4381-bb47-615c8301ea95',
    dateCreate: '2023-12-11T09:13:55.437747',
    dateUpdate: '2023-12-11T09:13:55.43775',
  },
  {
    name: 'Carlos Henrique',
    lastName: ' ',
    email: 'chsalvesjf@gmail.com',
    identityUserId: 'b612d766-c9c0-40c6-aaa7-10376eff441d',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '96732886-4c3d-4a78-9016-76337c57f5bb',
    dateCreate: '2021-10-27T00:00:00',
    dateUpdate: '2021-10-27T00:00:00',
  },
  {
    name: 'NELSON',
    lastName: 'DE MIRANDA',
    email: 'nelsinhonm@gmail.com',
    identityUserId: '2fa5f70a-0cf2-4e1b-bc11-be30adca2829',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '00e9962d-73d5-45ce-a402-1ff0462c9758',
    dateCreate: '2023-12-11T14:44:46.267236',
    dateUpdate: '2023-12-11T14:44:46.267238',
  },
  {
    name: 'Mikaele',
    lastName: 'Pires',
    email: 'mikaele.pires@outlook.com',
    identityUserId: 'ef049f77-b45e-402f-805b-b158c5fb0e36',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '1b63659e-626b-4981-aef4-a8ebc9ec8d29',
    dateCreate: '2023-12-26T15:23:02.391012',
    dateUpdate: '2023-12-26T15:23:02.391013',
  },
  {
    name: 'Paula Serrano',
    lastName: 'Cupcakes',
    email: 'cupcakesdapaulajf@gmail.com',
    identityUserId: '39380ef3-6603-484b-b574-fcae0de55d7a',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '0797efd6-621a-4417-8717-bcdf8d43fc5c',
    dateCreate: '2023-08-25T08:37:54.276126',
    dateUpdate: '2023-08-25T08:37:54.276127',
  },
  {
    name: 'Renata',
    lastName: 'Torres',
    email: 'renata@tred.com.br',
    identityUserId: 'f82cbe6f-beed-4a3a-a1dd-f95ee3186223',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: 'ed4fb40a-dbb5-4b62-95f0-ee1753aaddb2',
    dateCreate: '2024-01-05T08:23:41.129977',
    dateUpdate: '2024-01-05T08:23:41.129978',
  },
  {
    name: 'adriana',
    lastName: 'Monik',
    email: 'diskbrejadistribuidora.jf@gmail.com',
    identityUserId: 'edc85a75-8fcf-4a2a-8970-f8f2b8e60b15',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: 'df08edd2-b0ff-4907-b622-632035fbf63d',
    dateCreate: '2024-01-24T10:38:20.3694',
    dateUpdate: '2024-01-24T10:38:20.369402',
  },
  {
    name: 'Vitória ',
    lastName: 'Carelli',
    email: 'vcarelli01@icloud.com',
    identityUserId: '2d7d5e7b-cb6c-4012-892a-376b131766a9',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '1a685851-b342-412c-ba6c-737738025529',
    dateCreate: '2024-01-26T14:20:00.919835',
    dateUpdate: '2024-01-26T14:20:00.919836',
  },
  {
    name: 'Helena',
    lastName: 'Muller',
    email: 'helena@oencantamento.com',
    identityUserId: 'fd3ec0a2-e3b9-4a14-890f-e26f2d93c802',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '2455484c-acd2-4f1a-86a9-6aeba59debd2',
    dateCreate: '2024-02-19T09:32:42.096928',
    dateUpdate: '2024-02-19T09:32:42.096929',
  },
  {
    name: 'Saulo',
    lastName: 'Souza',
    email: 'saulo@iruam.com.br',
    identityUserId: 'eb1472fd-db6b-4290-901c-c066bc6a5b66',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '7e206f59-f915-4c18-aeae-bb4d3e6c962d',
    dateCreate: '2024-02-20T14:07:58.078157',
    dateUpdate: '2024-02-20T14:07:58.078158',
  },
  {
    name: 'MK Representações',
    lastName: ' ',
    email: 'mkrepresentacoesjf@gmail.com',
    identityUserId: '70b999fa-b2cc-4727-9b20-cb1398ed585f',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: 'a53fdbe7-5669-4ef1-9013-e3652ee1a195',
    dateCreate: '2022-03-08T00:00:00',
    dateUpdate: '2022-03-08T00:00:00',
  },
  {
    name: 'PAA',
    lastName: ' ',
    email: 'clinicapaa@gmail.com',
    identityUserId: '878f3b1a-e7df-429f-aca3-77c8723e55c5',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '1a44eb72-77e0-49aa-8ea6-3d62153738a1',
    dateCreate: '2023-03-16T00:00:00',
    dateUpdate: '2023-03-16T00:00:00',
  },
  {
    name: 'MAGNO',
    lastName: 'ANGELO',
    email: 'magno@hometechinternet.com.br',
    identityUserId: '6f06132e-2277-411f-95b4-d42d0963ea13',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: 'aa29c7b1-9ecb-4a76-9295-66d1ac039a88',
    dateCreate: '2024-03-04T10:10:06.905844',
    dateUpdate: '2024-03-04T10:10:06.905845',
  },
  {
    name: 'herley ',
    lastName: 'campos',
    email: 'navaheiro.jf@outlook.com',
    identityUserId: '26916826-6e2e-404f-9d1f-d329fc94bc24',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '47bfaca5-e88b-4407-9b22-1abf0849141a',
    dateCreate: '2024-03-07T09:59:14.981165',
    dateUpdate: '2024-03-07T09:59:14.981166',
  },
  {
    name: 'Stela ',
    lastName: 'Vieira',
    email: 'hopoflores@gmail.com',
    identityUserId: '46f7c111-da67-4246-a27e-eb6298743b61',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '27aaff2a-3201-401f-afce-a812ab5111fc',
    dateCreate: '2024-03-14T14:07:18.436284',
    dateUpdate: '2024-03-14T14:07:18.436285',
  },
  {
    name: 'Rafael ',
    lastName: 'Netto',
    email: 'faelnetto@gmail.com',
    identityUserId: 'ec6f4b30-a4c6-4c21-b3c1-4e1ee8d85d5f',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: 'd220b3f3-b6ea-45b7-a16d-75cbbe7e5edd',
    dateCreate: '2024-03-18T20:20:25.053157',
    dateUpdate: '2024-03-18T20:20:25.053158',
  },
  {
    name: 'SPM',
    lastName: 'Monte Castelo',
    email: 'castelarw046@gmail.com',
    identityUserId: 'c704a1db-c406-4f42-bf24-675973ceddea',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: 'f474502e-e663-4fac-970f-903b7ade5b21',
    dateCreate: '2024-04-09T20:07:33.743268',
    dateUpdate: '2024-04-09T20:07:33.74327',
  },
  {
    name: 'Roberta',
    lastName: 'Haddad',
    email: 'comercial@verovinostore.com',
    identityUserId: '9e7a4929-2371-45b0-ae3b-c440a2619974',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: '4a5f657c-3578-417d-86d7-0b9377102857',
    dateCreate: '2024-04-17T10:18:33.059833',
    dateUpdate: '2024-04-17T10:18:33.059835',
  },
  {
    name: 'Regiane',
    lastName: 'Zauza',
    email: 'regianezauza@ymail.com',
    identityUserId: '1eeef6e4-4b22-4a63-90b3-bda0234dea9c',
    isClient: false,
    signedTerm: false,
    companies: null,
    id: 'dff77c36-f163-4add-8f20-8ee7c4bf5e69',
    dateCreate: '2024-05-03T11:24:38.960464',
    dateUpdate: '2024-05-03T11:24:38.960465',
  },
  {
    name: 'Gustavo Terror',
    lastName: 'Guelber',
    email: 'gguelber@yahoo.com.br',
    identityUserId: 'B5c3dZ2ooOd7NVhRxCRDKdhmMWU2',
    isClient: true,
    signedTerm: true,
    companies: null,
    id: '9632ad62-26f9-4d28-ae5b-8cf00c0ccee7',
    dateCreate: '2023-03-16T18:56:33.974373',
    dateUpdate: '2023-03-16T18:56:33.974598',
  },
  {
    name: 'Fabio',
    lastName: 'Torres',
    email: 'fabio@tred.com.br',
    identityUserId: '3faT7LmhtoMqXrS1DmPtCRVYUD23',
    isClient: true,
    signedTerm: true,
    companies: null,
    id: '0aad8492-b439-47cd-a6ef-930dc32f495a',
    dateCreate: '2024-05-09T19:26:48.585281',
    dateUpdate: '2024-05-09T19:26:48.585282',
  },
];
