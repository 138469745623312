import axios from 'axios';

const apiClient = axios.create({
  // baseURL: 'https://localhost:5001/api/',
  // baseURL: 'https://api.vita.app.br/api',
  baseURL: 'https://testapi.vita.app.br/api',
  // baseURL: 'https://api.pranaconsultoria.com/api/',
  // baseURL: 'https://vitaapi2.automaway.com.br/api',

  withCredentials: false, // default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(function (config) {
  // Do something before request is sent
  let authKey = '';
  config.headers['Authorization'] = 'Basic ' + authKey;
  return config;
});

export default apiClient;

//  -- AXIOS EXAMPLES HTTP REQUETS --
// Source: https://blog.logrocket.com/how-to-make-http-requests-like-a-pro-with-axios/
// axios.request(config)
// axios.get(url[, config])
// axios.delete(url[, config])
// axios.head(url[, config])
// axios.options(url[, config])
// axios.post(url[, data[, config]])
// axios.put(url[, data[, config]])
// axios.patch(url[, data[, config]])
// For instance, the following code shows how the previous example could be written using the axios.post() method:

// axios.post('/login', {
//   firstName: 'Finn',
//   lastName: 'Williams'
// }).then((response) => {
//   console.log(response.data);
//   console.log(response.status);
//   console.log(response.statusText);
//   console.log(response.headers);
//   console.log(response.config);
// }, (error) => {
//   console.log(error);
// };
